import React, { useState } from "react";
import { RequestProvider } from "../providers/RequestProvider";
import {
  CookieValues,
  LinkValues,
  ResponseValues,
} from "../values/ConstantValues";
import { IndexView } from "../views/connectionrecovery/IndexView";
import { useCookies } from "react-cookie";
import { LoginController } from "./LoginController";

export const ConnectionProblemController = () => {
  const [msgField, setMsgField] = useState(null);
  const [attach, setAttach] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSucceed, setIsSucceed] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [cookies, setCookie] = useCookies([CookieValues.userCredential]);
  const request = new RequestProvider({
    "USER-TOKEN": cookies[CookieValues.userCredential],
  });

  const handlerMsgFieldOnChange = (e) => {
    setMsgField(e.target.value);
  };
  const handlerAttachFieldOnChange = (e) => {
    if (e.target.files) {
      setAttach(e.target.files[0]);
    }
  };

  const handlerOnSubmit = () => {
    setErrorMessage(null);
    if (!msgField) {
      setErrorMessage("لطفا  فیلد را به درستی پر کنید.");
      return;
    }
    setIsPending(true);
    var bodyFormData = new FormData();
    bodyFormData.append("message", msgField);
    if (attach) {
      bodyFormData.append("attach", attach);
    }
    request.post(
      LinkValues.backend.servers.connectionProblem._self(false),
      bodyFormData,
      (response) => {
        if (response.status == 200 && response.data.hasOwnProperty("code")) {
          if (response.data.code == ResponseValues.code.ok) {
            setIsSucceed(true);
          } else {
            setErrorMessage(response.data.message);
            setIsPending(false);
          }
        }
      },
      null,
      null,
      { "Content-Type": "multipart/form-data" }
    );
  };

  React.useEffect(() => {
    if (isSucceed) {
      setTimeout(() => window.location.reload(), 2000);
    }
  }, [isSucceed]);

  return (
    <>
      {((!cookies || !cookies.hasOwnProperty(CookieValues.userCredential)) && (
        <LoginController />
      )) || (
        <IndexView
          isPending={isPending}
          errorMessage={errorMessage}
          msgFieldValue={msgField}
          msgFieldOnChange={handlerMsgFieldOnChange}
          attachFieldOnChange={handlerAttachFieldOnChange}
          onSubmit={handlerOnSubmit}
          isSucceed={isSucceed}
        />
      )}
    </>
  );
};
