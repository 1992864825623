import { Android, Apple, DesktopWindows } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { MasterView } from "../MasterView";

export const AndroidOneClickView = () => {
  return (
    <MasterView marginY={70}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} xs={12}>
            <Typography variant="h6" gutterBottom>
              1. دانلود اپلیکیشن
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ fontWeight: 700 }}
            >
              از طریق صفحه "دانلود" آخرین نسخه اپلیکیشن را دانلود و نصب کنید.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              component="a"
              href="/download"
              style={{ fontWeight: 700 }}
            >
              دانلود اپلیکیشن
            </Typography>
            <br />
            <br />
            <hr />
            <br />
            <Typography variant="h6" gutterBottom>
              2. دریافت کانفیگ
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ fontWeight: 700 }}
            >
              پس از ورود به حساب خود وارد صفحه اصلی شوید. از باکس "پروتکل" گزینه
              VMESS را انتخاب کنید. سپس از باکس "کانفیگ" روی فیلد "کد اتصال"
              کلیک کنید تا کانفیگ کپی شود.
            </Typography>
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <img src="/assets/learn/copycode.png" />
            </div>
            <br />
            <hr />
            <br />
            <Typography variant="h6" gutterBottom>
              3. تنظیم کانفیگ و اتصال
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ fontWeight: 700 }}
            >
              برای اتصال به VPN، کد اتصال کپی شده را مطابق تصویر زیر در اپلیکیشن
              OneClick وارد کنید:
            </Typography>
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <img src="/assets/learn/oneclick-apk.jpg" />
            </div>
            <br />
            <hr />
            <br />
            <Typography variant="h6" gutterBottom>
              4. اتصال با موفقیت انجام شد
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};
