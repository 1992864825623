import { ErrorOutline } from "@mui/icons-material";
import { Alert, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MasterView } from "../MasterView";

export const IndexView = () => {
  return (
    <MasterView marginY={70}>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            {CommunityView()}
          </Grid>
          <Grid item md={6} xs={12}>
            {ContactView()}
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};

const CommunityView = () => {
  return (
    <div className="containerBlock">
      <Typography
        variant="h5"
        gutterBottom
        className="p-downloadIcons"
        fontWeight={700}
        textAlign="center"
      >
        شبکه های اجتماعی
      </Typography>
      <hr />
      {/* <Grid container direction="row" sx={{ marginTop: 2 }}>
        <Grid item xs>
          <Typography variant="body2" gutterBottom textAlign="start">
            اینستاگرام
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            gutterBottom
            fontWeight={700}
            component="a"
            href="https://instagram.com"
            textAlign="end"
          >
            پیج اصلی
          </Typography>
        </Grid>
      </Grid> */}
      <Grid container direction="row" sx={{ marginTop: 2 }}>
        <Grid item xs>
          <Typography variant="body2" gutterBottom textAlign="start">
            تلگرام
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            gutterBottom
            fontWeight={700}
            component="a"
            href="https://t.me/IGTeam_v"
            textAlign="end"
          >
            کانال اطلاع رسانی
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const ContactView = () => {
  const navigate = useNavigate();
  return (
    <div className="containerBlock">
      <Typography
        variant="h5"
        gutterBottom
        className="p-downloadIcons"
        fontWeight={700}
        textAlign="center"
      >
        پشتیبانی
      </Typography>
      <hr />
      <Grid container direction="column" sx={{ marginTop: 2 }}>
        <Grid item>
          <Alert
            severity="error"
            icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
            sx={{ marginBottom: "12px", fontWeight: 700, fontSize: 15 }}
          >
            پاسخگویی از ساعت 10 صبح تا 7 بعدازظهر
          </Alert>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            gutterBottom
            fontWeight={700}
            onClick={() => navigate("/recovery")}
            textAlign="end"
            sx={{
              cursor: "pointer",
              color: "#5200ef",
              textDecoration: "underline",
            }}
          >
            بازیابی اکانت
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            gutterBottom
            fontWeight={700}
            onClick={() => navigate("/tracking")}
            textAlign="end"
            sx={{
              cursor: "pointer",
              color: "#5200ef",
              textDecoration: "underline",
            }}
          >
            پیگیری اکانت دریافت نشده
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            gutterBottom
            fontWeight={700}
            onClick={() => navigate("/connection_problems")}
            textAlign="end"
            sx={{
              cursor: "pointer",
              color: "#5200ef",
              textDecoration: "underline",
            }}
          >
            مشکلات اتصال
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
