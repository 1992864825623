import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { RequestProvider } from "../providers/RequestProvider";
import {
  CookieValues,
  LinkValues,
  ResponseValues,
} from "../values/ConstantValues";
import { IndexView } from "../views/account/IndexView";

const AccountController = () => {
  const [orderList, setOrderList] = useState(null);
  const [expiredAt, setExpiredAt] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cookies, setCookie] = useCookies([CookieValues.userCredential]);
  const request = new RequestProvider({
    "USER-TOKEN": cookies[CookieValues.userCredential],
  });

  useEffect(() => {
    if (orderList === null) {
      request.get(LinkValues.backend.account.user.info(), null, (response) => {
        if (response.status == 200 && response.data.hasOwnProperty("code")) {
          if (response.data.code == ResponseValues.code.ok) {
            setUsername(response.data.data.username);
            setPassword(response.data.data.password);
            setEmail(response.data.data.email);
            setExpiredAt(response.data.data.expired_at);
            setOrderList(response.data.data.orders);
            return;
          }
        }
        setOrderList([]);
      });
    }
  }, [orderList]);

  return (
    <IndexView
      emailAddress={email}
      username={username}
      password={password}
      expiredAt={expiredAt}
      list={orderList}
    />
  );
};

export default AccountController;
