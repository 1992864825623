import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { MasterView } from "../MasterView";

export const EmptyListView = ({ children }) => {
  return (
    <Container>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={6} xs={12} style={{ textAlign: "center" }}>
          <Typography
            variant="h1"
            gutterBottom
            style={{ fontWeight: 700, opacity: 0.4 }}
          >
            <div style={{ transform: "scaleX(-1)", display: "inline-block" }}>
              :(
            </div>
          </Typography>
          <Typography variant="body2" gutterBottom style={{ fontWeight: 700 }}>
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
