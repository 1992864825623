import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { AcUnit, AllOut, FlashOn } from "@mui/icons-material";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { RequestProvider } from "../../providers/RequestProvider";
import {
  CookieValues,
  LinkValues,
  ResponseValues,
} from "../../values/ConstantValues";

const NavigationView = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [cookies, setCookie, removeCookie] = useCookies([
    CookieValues.userCredential,
  ]);
  const navigate = useNavigate();
  const pages = [
    {
      title: "خرید",
      route: "/pricing",
    },
    {
      title: "پشتیبانی",
      route: "/contact",
    },
  ];
  const pagesAuth = [
    {
      title: "خرید",
      route: "/pricing",
    },
    {
      title: "دانلود اپلیکیشن",
      route: "/download",
    },
    {
      title: "آموزش",
      route: "/learn",
    },
    {
      title: "پشتیبانی",
      route: "/contact",
    },
  ];
  const authPages = [
    {
      title: "ورود",
      route: "/login",
      sx: {
        mx: 1,
        color: "#ffc107",
        display: "block",
        border: "1px solid #ffc107",
        borderRadius: 2,
      },
      // sx: { mx: 1, color: "white", display: "block" },
    },
    // {
    //   title: "ایجاد حساب",
    //   route: "/signup",
    //   sx: {
    //     mx: 1,
    //     color: "#ffc107",
    //     display: "block",
    //     border: "1px solid #ffc107",
    //     borderRadius: 2,
    //   },
    // },
  ];
  const panelPages = [
    {
      title: "پنل کاربری",
      route: "/",
      sx: { mx: 1, color: "white", display: "block" },
    },
    {
      title: "مشخصات",
      route: "/account",
      sx: { mx: 1, color: "white", display: "block" },
    },
    {
      title: "خروج",
      onCLick: () => {
        const request = new RequestProvider();
        request.get(
          LinkValues.backend.account.user.logout(),
          null,
          (response) => {
            if (response.status == 200 && response.data.hasOwnProperty("code")) {
              if (response.data.code == ResponseValues.code.ok) {
                removeCookie(CookieValues.userCredential);
                window.location.href = "/";
                return;
              }
            }
          },
          null,
          null,
          {
            "USER-TOKEN": cookies[CookieValues.userCredential],
          }
        );
      },
      sx: {
        mx: 1,
        color: "#ffc107",
        display: "block",
        border: "1px solid #ffc107",
        borderRadius: 2,
      },
    },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlerOpenPage = (route, closeMenu) => {
    if (typeof route === "function") {
      route();
    } else {
      if (route.startsWith("http")) {
        window.location.href = route;
      } else {
        navigate(route);
      }
    }
    if (closeMenu) {
      handleCloseNavMenu();
    }
  };

  const BuildMobileView = () => (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <FlashOn sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            fontWeight: 800,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          وی‌پی‌نو
        </Typography>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {(!cookies.hasOwnProperty(CookieValues.userCredential) &&
            pages.map((page, index) => (
              <MenuItem
                key={index}
                onClick={() => handlerOpenPage(page.route, true)}
              >
                <Typography textAlign="center">{page.title}</Typography>
              </MenuItem>
            ))) ||
            pagesAuth.map((page, index) => (
              <MenuItem
                key={index}
                onClick={() => handlerOpenPage(page.route, true)}
              >
                <Typography textAlign="center">{page.title}</Typography>
              </MenuItem>
            ))}
          <Divider />
          {(!cookies.hasOwnProperty(CookieValues.userCredential) &&
            authPages.map((page, index) => (
              <MenuItem
                key={index}
                onClick={() => handlerOpenPage(page.route, true)}
              >
                <Typography textAlign="center">{page.title}</Typography>
              </MenuItem>
            ))) ||
            panelPages.map((page, index) => (
              <MenuItem
                key={index}
                onClick={() =>
                  handlerOpenPage(
                    page.hasOwnProperty("route") ? page.route : page.onCLick,
                    true
                  )
                }
              >
                <Typography textAlign="center">{page.title}</Typography>
              </MenuItem>
            ))}
        </Menu>
      </Box>
    </>
  );

  const BuildDesktopView = () => (
    <>
      <FlashOn sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
      <Typography
        variant="h6"
        noWrap
        component="a"
        href="/"
        sx={{
          mr: 2,
          display: { xs: "none", md: "flex" },
          fontWeight: 800,
          color: "inherit",
          textDecoration: "none",
        }}
      >
        وی‌پی‌نو
      </Typography>

      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "flex" },
          padding: "0 10px",
        }}
      >
        {(!cookies.hasOwnProperty(CookieValues.userCredential) &&
          pages.map((page, index) => (
            <Button
              key={index}
              onClick={() => handlerOpenPage(page.route, true)}
              sx={{
                mx: 1,
                color: "white",
                display: "block",
              }}
            >
              {page.title}
            </Button>
          ))) ||
          pagesAuth.map((page, index) => (
            <Button
              key={index}
              onClick={() => handlerOpenPage(page.route, true)}
              sx={{
                mx: 1,
                color: "white",
                display: "block",
              }}
            >
              {page.title}
            </Button>
          ))}
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
        }}
      >
        {(!cookies.hasOwnProperty(CookieValues.userCredential) &&
          authPages.map((page, index) => (
            <Button
              key={index}
              onClick={() => handlerOpenPage(page.route)}
              sx={page.sx}
            >
              {page.title}
            </Button>
          ))) ||
          panelPages.map((page, index) => (
            <Button
              key={index}
              onClick={() =>
                handlerOpenPage(
                  page.hasOwnProperty("route") ? page.route : page.onCLick
                )
              }
              sx={page.sx}
            >
              {page.title}
            </Button>
          ))}
      </Box>
    </>
  );

  return (
    <AppBar
      position="static"
      sx={{
        background: "#2e46cf",
      }}
      elevation={0}
    >
      <Container>
        <Toolbar disableGutters>
          {BuildMobileView()}
          {BuildDesktopView()}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavigationView;
