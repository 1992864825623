import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";

export const ConfirmationDialogRaw = (props) => {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  React.useEffect(() => {
    if (value) {
      handleOk();
    }
  }, [value]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>موقعیت سرور</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
          className="rg_countrySelection"
        >
          {props.datalist &&
            props.datalist.map((option, index) => (
              <FormControlLabel
                value={option.country_code}
                key={index}
                control={<Radio />}
                label={
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {option.country_name}
                    <img
                      src={option.flag}
                      style={{ margin: "0px 17px 0", height: "25px" }}
                    />
                  </div>
                }
              />
            ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          لغو
        </Button>
        {/* <Button onClick={handleOk}>انتخاب</Button> */}
      </DialogActions>
    </Dialog>
  );
};

export const OperatorChangeDialogRaw = (props) => {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  React.useEffect(() => {
    if (value) {
      handleOk();
    }
  }, [value]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>انتخاب اپراتور</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
          className="rg_countrySelection"
        >
          {props.datalist &&
            props.datalist.map((option, index) => (
              <FormControlLabel
                value={option.code}
                key={index}
                control={<Radio />}
                label={
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {option.name}
                    <img
                      src={option.flag}
                      style={{ margin: "0px 17px 0", height: "25px" }}
                    />
                  </div>
                }
              />
            ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          لغو
        </Button>
        {/* <Button onClick={handleOk}>انتخاب</Button> */}
      </DialogActions>
    </Dialog>
  );
};

export const BeforeConnectionProblemDialogRaw = (props) => {
  const { onClose, open, ...other } = props;
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
    }
  }, [open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>مهم: 🚨متن زیر را حتما با دقت بخوانید🚨</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          + از پروتکل پیشنهادی استفاده کنید. <br />
          + دقیقا طبق اموزش انجام بدید. <br />
          + طبق اپراتور اینترنت خودتون سرور رو انتخاب کنید. <br />
          <br />
          سرور ايرانسل و... : برای ایرانسل و باقی اپراتورها غیر از همراه اول و
          adsl مخابرات
          <br />
          سرور همراه اول و ثابت : برای همراه اول و adsl مخابرات <br />
          <br />
          + اتصال اینترنت خود را بررسی و از دسترسی خود مطمئن شوید. <br />
          ‌+ شرایط اینترنت کشور هم درنظر داشته باشید <br />
          اینترنت در ساعات مختلف و اپراتور های مختلف و مناطق مختلف کشور اختلال
          دارد
          <br />
          <br />
          درصورت برطرف نشدن مشکل، بدون حاشیه و فقط مشکل خودرا بیان کنید و
          همینطور از تمام مراحلی که انجام میدید اسکرین رکورد ارسال کنید <br />
          <br />
          جهت دریافت پاسخ صبور باشید
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          باشه
        </Button>
        {/* <Button onClick={handleOk}>انتخاب</Button> */}
      </DialogActions>
    </Dialog>
  );
};
