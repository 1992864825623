import React, { useState } from "react";
import { RequestProvider } from "../providers/RequestProvider";
import {
  CookieValues,
  LinkValues,
  ResponseValues,
} from "../values/ConstantValues";
import { IndexView } from "../views/forgot/IndexView";
import { useCookies } from "react-cookie";

export const ForgotController = () => {
  const [emailField, setEmailField] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [sendSuccessfully, setSendSuccessfully] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const request = new RequestProvider();

  const handlerEmailFieldOnChange = (e) => {
    setEmailField(e.target.value);
  };

  const handlerOnSubmit = () => {
    setErrorMessage(null);
    if (!emailField) {
      setErrorMessage("لطفا تمام فیلد ها را به درستی پر کنید.");
      return;
    }
    setIsPending(true);
    request.post(
      LinkValues.backend.account.user.forgot(false),
      {
        email: emailField,
      },
      (response) => {
        if (response.status == 200 && response.data.hasOwnProperty("code")) {
          if (response.data.code == ResponseValues.code.ok) {
            setSendSuccessfully(true);
          } else {
            setErrorMessage(response.data.message);
          }
        }
      },
      null,
      () => {
        setIsPending(false);
      }
    );
  };

  return (
    <IndexView
      isPending={isPending}
      errorMessage={errorMessage}
      sendSuccessfully={sendSuccessfully}
      emailFieldValue={emailField}
      emailFieldOnChange={handlerEmailFieldOnChange}
      onSubmit={handlerOnSubmit}
    />
  );
};
