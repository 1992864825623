import axios from "axios";
import { LinkValues, BackendValues } from "../values/ConstantValues";

export class RequestProvider {
  constructor(headers) {
    this.instance = axios.create({
      baseURL: LinkValues.backend.url,
      timeout: 10000,
      headers: {
        "APP-TOKEN": BackendValues.appToken,
        "Content-Type": "application/json",
        ...headers,
      },
    });
  }

  async get(url, queryParams, onSuccess, onError, onFinal, headers) {
    var query = "";
    if (queryParams) {
      query = "?" + new URLSearchParams(queryParams).toString();
    }
    const _res = await this.instance
      .get(`${url}${query}`,{
      headers: headers,
      })
      .then(onSuccess)
      .catch(onError)
      .finally(onFinal);
    return _res;
  }

  async post(url, data, onSuccess, onError, onFinal, headers) {
    const _res = await this.instance
      .post(url, data, {
        headers: headers,
      })
      .then(onSuccess)
      .catch(onError)
      .finally(onFinal);
    return _res;
  }
}
