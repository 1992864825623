import React, { useState } from "react";
import { RequestProvider } from "../providers/RequestProvider";
import {
  CookieValues,
  LinkValues,
  ResponseValues,
} from "../values/ConstantValues";
import { IndexView } from "../views/tracking/IndexView";
import { useCookies } from "react-cookie";

export const TrackingController = () => {
  const [cardField, setCardField] = useState(null);
  const [emailField, setEmailField] = useState(null);
  const [msgField, setMsgField] = useState(null);
  const [repField, setRepField] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const request = new RequestProvider();

  const handlerCardFieldOnChange = (e) => {
    setCardField(e.target.value);
  };

  const handlerEmailFieldOnChange = (e) => {
    setEmailField(e.target.value);
  };

  const handlerMsgFieldOnChange = (e) => {
    setMsgField(e.target.value);
  };

  const handlerRepFieldOnChange = (e) => {
    if (e.target.files) {
      setRepField(e.target.files[0]);
    }
  };

  const handlerOnSubmit = () => {
    setErrorMessage(null);
    if (!emailField || !cardField) {
      setErrorMessage(
        "لطفا تمام فیلد های دارای ستاره (*) را به درستی پر کنید."
      );
      return;
    }
    setIsPending(true);
    var bodyFormData = new FormData();
    bodyFormData.append("email", emailField);
    bodyFormData.append("card", cardField);
    if (repField) {
      bodyFormData.append("reciept", repField);
    }
    if (msgField) {
      bodyFormData.append("message", msgField);
    }
    request.post(
      LinkValues.backend.account.user.track(false),
      bodyFormData,
      (response) => {
        if (response.status == 200 && response.data.hasOwnProperty("code")) {
          if (response.data.code == ResponseValues.code.ok) {
            setAccountDetails(response.data.data);
          } else {
            setErrorMessage(response.data.message);
          }
        }
      },
      null,
      () => {
        setIsPending(false);
      },
      { "Content-Type": "multipart/form-data" }
    );
  };

  return (
    <IndexView
      isPending={isPending}
      errorMessage={errorMessage}
      emailFieldValue={emailField}
      emailFieldOnChange={handlerEmailFieldOnChange}
      cardFieldValue={cardField}
      cardFieldOnChange={handlerCardFieldOnChange}
      repFieldValue={repField}
      repFieldOnChange={handlerRepFieldOnChange}
      msgFieldValue={msgField}
      msgFieldOnChange={handlerMsgFieldOnChange}
      accountDetails={accountDetails}
      getBack={() => setAccountDetails(null)}
      onSubmit={handlerOnSubmit}
    />
  );
};
