import { Android, Apple, DesktopWindows } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { MasterView } from "../MasterView";

export const AndroidView = () => {
  return (
    <MasterView marginY={70}>
      <Container>
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}></Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};
