import { ErrorOutline } from "@mui/icons-material";
import { Alert, Button, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MasterView } from "../MasterView";

export const IndexView = ({ errorMessage, data }) => {
  const navigate = useNavigate();
  return (
    <MasterView marginY={70}>
      <Container>
        {(!data &&
          ((!errorMessage && <>چند لحظه صبر کنید...</>) || (
            <Alert
              severity="error"
              icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
              sx={{ marginBottom: "12px" }}
            >
              {errorMessage}
            </Alert>
          ))) || (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={6} xs={12}>
              <div className="containerBlock">
                <Typography
                  variant="h5"
                  gutterBottom
                  fontWeight={700}
                  sx={{ margin: "0 0 20px" }}
                  textAlign="center"
                >
                  {data.product_name}
                </Typography>
                <hr />
                {data.username || data.password ? (
                  <>
                    <div
                      style={{
                        background: "rgb(238, 238, 238)",
                        border: "2px dashed #c55",
                        padding: "0px 15px 10px",
                        borderRadius: "10px",
                      }}
                    >
                      {data.username && (
                        <Grid container direction="row" sx={{ marginTop: 2 }}>
                          <Grid item xs>
                            <Typography
                              variant="body2"
                              gutterBottom
                              textAlign="start"
                            >
                              نام کاربری
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="caption"
                              gutterBottom
                              fontWeight={700}
                              textAlign="end"
                            >
                              {data.username}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {data.password && (
                        <Grid container direction="row" sx={{ marginTop: 2 }}>
                          <Grid item xs>
                            <Typography
                              variant="body2"
                              gutterBottom
                              textAlign="start"
                            >
                              پسورد
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="caption"
                              gutterBottom
                              fontWeight={700}
                              textAlign="end"
                            >
                              {data.password}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Button
                        variant="contained"
                        onClick={() => navigate("/login")}
                        sx={{ margin: "10px 0 5px" }}
                        fullWidth
                      >
                        ورود به پنل کاربری
                      </Button>
                    </div>
                    <hr />
                  </>
                ) : (
                  <></>
                )}
                <Grid container direction="row" sx={{ marginTop: 2 }}>
                  <Grid item xs>
                    <Typography variant="body2" gutterBottom textAlign="start">
                      مدت
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      gutterBottom
                      fontWeight={700}
                      textAlign="end"
                    >
                      {data.duration} {data.timestamp}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" sx={{ marginTop: 1 }}>
                  <Grid item xs>
                    <Typography variant="body2" gutterBottom textAlign="start">
                      کد پیگیری
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      gutterBottom
                      fontWeight={700}
                      textAlign="end"
                    >
                      {data.refid}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" sx={{ marginTop: 1 }}>
                  <Grid item xs>
                    <Typography variant="body2" gutterBottom textAlign="start">
                      وضعیت
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      gutterBottom
                      fontWeight={700}
                      textAlign="end"
                    >
                      {data.state}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" sx={{ marginTop: 1 }}>
                  <Grid item xs>
                    <Typography variant="body2" gutterBottom textAlign="start">
                      تاریخ شروع
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      gutterBottom
                      fontWeight={700}
                      textAlign="end"
                    >
                      {data.created_at}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" sx={{ marginTop: 1 }}>
                  <Grid item xs>
                    <Typography variant="body2" gutterBottom textAlign="start">
                      تاریخ انقضا
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      gutterBottom
                      fontWeight={700}
                      textAlign="end"
                    >
                      {data.expired_at}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}
      </Container>
    </MasterView>
  );
};
