import {
  CheckCircle,
  ErrorOutline,
  TextFieldsOutlined,
} from "@mui/icons-material";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MasterView } from "../MasterView";

export const IndexView = ({
  isPending = false,
  errorMessage,
  sendSuccessfully,
  emailFieldValue,
  emailFieldOnChange,
  onSubmit,
}) => {
  const navigate = useNavigate();
  return (
    <MasterView marginY={70}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} xs={12}>
            <div className="containerBlock">
              <Typography
                variant="h5"
                gutterBottom
                fontWeight={700}
                sx={{ margin: "20px 0" }}
                textAlign="center"
              >
                بازیابی اکانت
              </Typography>
              <Alert
                severity="info"
                icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
                sx={{ margin: "20px 0" }}
              >
                از طریق این بخش می‌توانید اکانت های خریداری شده خود را بازیابی
                کنید.
                <br />
                برای بازیابی کافی‌است که آدرس ایمیل خود را وارد کنید و پس از آن
                ایمیلی حاوی تمامی اکانتهای خریداری شده‌تان را دریافت خواهید
                کرد.(درصورتی که ایمیل دریافت نکردید، پوشه اسپم ایمیل خود را نیز
                <br />
                چک کنید) آدرس ایمیل خود را وارد نمایید: ( آدرس ایمیل www ندارد )
              </Alert>
              {sendSuccessfully && (
                <Alert
                  severity="success"
                  icon={<CheckCircle sx={{ margin: "0 0 0 12px" }} />}
                  sx={{ marginBottom: "12px" }}
                >
                  اطلاعات حساب کاربری به ایمیل شما ارسال شد. لطفا پوشه اسپم خود
                  را نیز چک کنید.
                </Alert>
              )}
              {errorMessage && (
                <Alert
                  severity="error"
                  icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
                  sx={{ marginBottom: "12px" }}
                >
                  {errorMessage}
                </Alert>
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      id="standard-basic"
                      label="ایمیل"
                      variant="standard"
                      value={emailFieldValue}
                      onChange={emailFieldOnChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSubmit}
                      disabled={isPending ? true : false}
                      type="submit"
                      fullWidth
                    >
                      ارسال اطلاعات حساب
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};
