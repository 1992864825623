import React from "react";
import { IndexView } from "../views/learn/IndexView";
import { AndroidView } from "../views/learn/AndroidView";
import { AndroidV2rayngView } from "../views/learn/AndroidV2rayngView";
import { AndroidVipinoView } from "../views/learn/AndroidVipinoView";
import { AndroidOneClickView } from "../views/learn/AndroidOneClickView";
import { AndroidOpenConnectView } from "../views/learn/AndroidOpenConnectView";
import { AndroidPPTPView } from "../views/learn/AndroidPPTPView";
import { AndroidIPSecView } from "../views/learn/AndroidIPSecView";
import { IosView } from "../views/learn/IosView";
import { IosOneClickView } from "../views/learn/IosOneClickView";
import { IosIPSecView } from "../views/learn/IosIPSecView";
import { IosAnyConnectView } from "../views/learn/IosAnyConnectView";
import { IosOpenConnectView } from "../views/learn/IosOpenConnectView";
import { AndroidAnyConnectView } from "../views/learn/AndroidAnyConnectView";
import { WinOpenConnectView } from "../views/learn/WinOpenConnectView";

export const LearnController = () => {
  return <IndexView />;
};

export const AndroidController = () => {
  return <AndroidView />;
};
export const AndroidVipinoController = () => {
  return <AndroidVipinoView />;
};
export const AndroidV2rayngController = () => {
  return <AndroidV2rayngView />;
};
export const AndroidOneClickController = () => {
  return <AndroidOneClickView />;
};
export const AndroidOpenConnectController = () => {
  return <AndroidOpenConnectView />;
};
export const AndroidAnyConnectController = () => {
  return <AndroidAnyConnectView />;
};
export const AndroidPPTPController = () => {
  return <AndroidPPTPView />;
};
export const AndroidIPSecController = () => {
  return <AndroidIPSecView />;
};

export const IosController = () => {
  return <IosView />;
};
export const IosOneClickController = () => {
  return <IosOneClickView />;
};

export const IosIPSecController = () => {
  return <IosIPSecView />;
};
export const IosAnyConnectController = () => {
  return <IosAnyConnectView />;
};

export const IosOpenConnectController = () => {
  return <IosOpenConnectView />;
};

export const WinOpenConnectController = () => {
  return <WinOpenConnectView />;
};
