import { Android, Apple, DesktopWindows } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { MasterView } from "../MasterView";

export const IndexView = () => {
  const navigate = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <MasterView marginY={70}>
      <Container>
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            {VipinoSmartView(navigate)}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            {AnyConnectView(navigate)}
          </Grid>
          {/*
          <Grid item lg={4} md={6} xs={12}>
            {OpenConnectView(navigate)}
          </Grid> <Grid item lg={4} md={6} xs={12}>
            {V2RayView(navigate)}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            {OneClickView(navigate)}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            {NetchView(navigate)}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            {I2RayView(navigate)}
          </Grid> */}
        </Grid>
      </Container>
    </MasterView>
  );
};

const V2RayView = (navigate) => {
  return (
    <div className="containerBlock ">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/v2rayng.png"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              دانلود V2RayNG
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: Vmess, VLESS, Trojan, Socks, Shadowsocks
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("https://asset.vipino.net/app/v2rayng.apk");
              }}
              fullWidth
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const OneClickView = (navigate) => {
  return (
    <div className="containerBlock ">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/oneclick.png"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              دانلود OneClick
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: Vmess, VLESS, Trojan, Socks, Shadowsocks
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("https://asset.vipino.net/app/oneclick.apk");
              }}
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate(
                  "https://apps.apple.com/us/app/oneclick-safe-easy-fast/id1545555197"
                );
              }}
            >
              <Apple
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              آیفون
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const NetchView = (navigate) => {
  return (
    <div className="containerBlock ">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/netch.jpeg"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              دانلود Netch
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: Vmess, VLESS, Trojan, SOCKS5, Shadowsocks, Wireguard
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("https://asset.vipino.net/app/netch.7z");
              }}
            >
              <DesktopWindows
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              ویندوز
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const OpenConnectView = (navigate) => {
  return (
    <div className="containerBlock">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/openconnect.jpeg"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              دانلود OpenConnect
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: Cisco OpenConnect
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("https://asset.vipino.net/app/cisco_openconnect.apk");
              }}
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate(
                  "https://apps.apple.com/us/app/cisco-secure-client/id1135064690"
                );
              }}
            >
              <Apple
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              آیفون
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("https://asset.vipino.net/app/cisco_openconnect.exe");
              }}
            >
              <DesktopWindows
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              ویندوز
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const AnyConnectView = (navigate) => {
  return (
    <div className="containerBlock">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/anyconnect.png"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              دانلود AnyConnect
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: Cisco AnyConnect
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          {/* <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("https://asset.vipino.net/app/cisco_anyconnect.apk");
              }}
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid> */}
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate(
                  "https://apps.apple.com/us/app/cisco-secure-client/id1135064690"
                );
              }}
            >
              <Apple
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              آیفون
            </Button>
          </Grid>
          <Grid item xs={6}>  
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("https://asset.vipino.net/app/cisco_anyconnect.msi");
              }}
            >
              <DesktopWindows
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              ویندوز
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const VipinoSmartView = (navigate) => {
  return (
    <div className="containerBlock">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/vipino.png"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              کانکشن هوشمند وی‌پی‌نو
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              * برای اندروید 6 به بالا
              <br />
              پشتیبانی از همه پروتکل های فعال
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("https://asset.vipino.net/app/vipinio-official-app.apk");
              }}
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid> 
        </Grid>
      </Grid>
    </div>
  );
};

const I2RayView = (navigate) => {
  return (
    <div className="containerBlock">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/i2ray.png"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              دانلود I2Ray
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: Vmess, VLESS, Trojan, Socks, Shadowsocks
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("https://apps.apple.com/us/app/i2ray/id1445270056");
              }}
            >
              <Apple
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              آیفون
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
