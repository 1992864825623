import { createTheme, ThemeProvider as MThemeProvider } from "@mui/material";
import React, { useContext } from "react";
import { AppStyles, AppTheme } from "../values/ThemeValues";

const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
  const theme = createTheme(AppTheme);

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
      }}
    >
      <MThemeProvider theme={theme}>{children}</MThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeProvider = () => {
  return useContext(ThemeContext);
};
