import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MainController } from "./controller/MainController";
import ErrorController from "./controller/ErrorController";
import { ThemeProvider } from "./providers/ThemeProvider";
import { LoginController } from "./controller/LoginController";
import { SignupController } from "./controller/SignupController";
import {
  PricingController,
  PricingEmailController,
} from "./controller/PricingController";
import { DownloadController } from "./controller/DownloadController";
import { CookiesProvider } from "react-cookie";
import AccountController from "./controller/AccountController";
import {
  AndroidVipinoController,
  AndroidAnyConnectController,
  AndroidController,
  AndroidIPSecController,
  AndroidOneClickController,
  AndroidOpenConnectController,
  AndroidPPTPController,
  AndroidV2rayngController,
  IosAnyConnectController,
  IosController,
  IosIPSecController,
  IosOneClickController,
  IosOpenConnectController,
  LearnController,
  WinOpenConnectController,
} from "./controller/LearnController";
import { OrderController } from "./controller/OrderController";
import ContactController from "./controller/ContactController";
import { ForgotController } from "./controller/ForgotController";
import { ConnectionProblemController } from "./controller/ConnectionProblemController";
import { TrackingController } from "./controller/TrackingController";
import { RecoveryController } from "./controller/RecoveryController";

function App() {
  return (
    <CookiesProvider>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainController />} />
            <Route path="/login" element={<LoginController />} />
            <Route path="/forget" element={<ForgotController />} />
            <Route path="/recovery" element={<RecoveryController />} />
            <Route
              path="/connection_problems"
              element={<ConnectionProblemController />}
            />
            <Route path="/tracking" element={<TrackingController />} />
            <Route path="/signup" element={<MainController />} />
            <Route path="/contact" element={<ContactController />} />
            <Route path="/pricing" element={<PricingController />} />
            <Route
              path="/pricing/confirm/:code"
              element={<PricingEmailController />}
            />
            <Route path="/download" element={<DownloadController />} />
            <Route path="/account" element={<AccountController />} />
            <Route path="/learn" element={<LearnController />} />
            <Route path="/learn/android" element={<AndroidController />} />
            <Route
              path="/learn/android/v2rayng"
              element={<AndroidV2rayngController />}
            />
            <Route
              path="/learn/android/oneclick"
              element={<AndroidOneClickController />}
            />
            <Route
              path="/learn/android/coc"
              element={<AndroidOpenConnectController />}
            />
            <Route
              path="/learn/android/cac"
              element={<AndroidAnyConnectController />}
            />
            <Route
              path="/learn/android/vipino"
              element={<AndroidVipinoController />}
            />
            <Route
              path="/learn/android/pptp"
              element={<AndroidPPTPController />}
            />
            <Route
              path="/learn/android/ipsec"
              element={<AndroidIPSecController />}
            />
            <Route path="/learn/ios" element={<IosController />} />
            <Route
              path="/learn/ios/oneclick"
              element={<IosOneClickController />}
            />
            <Route path="/learn/ios/ipsec" element={<IosIPSecController />} />
            <Route
              path="/learn/ios/cac"
              element={<IosAnyConnectController />}
            />
            <Route
              path="/learn/ios/coc"
              element={<IosOpenConnectController />}
            />
            <Route
              path="/learn/win/cac"
              element={<WinOpenConnectController />}
            />
            <Route
              path="/orders/details/:refid"
              element={<OrderController />}
            />
            <Route path="*" element={<ErrorController />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </CookiesProvider>
  );
}

export default App;
