import React from "react";

const SpeedView = ({ padding }) => {
  return (
    <div style={{ padding: padding }}>
      <svg data-name="Layer 1" viewBox="0 0 855.15669 581.90681">
        <path
          d="M696.92661,503.79661a5.5,5.5,0,0,1,5.5,5.49994v53.20618h105.5a5.5,5.5,0,0,1,0,11h-105.5v184h-11V509.29655A5.5,5.5,0,0,1,696.92661,503.79661Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#e6e6e6"
        />
        <path
          d="M403.33253,756.2532l-19.83221.54759a13.81232,13.81232,0,0,1-8.48582-2.3789c-2.15367-1.602-3.14159-3.76763-2.70847-5.94251l3.90258-19.61285c.73546-3.7,5.26958-6.49709,10.77994-6.6493l10.95246-.30244c5.51034-.15215,10.19188,2.39045,11.1305,6.04428l4.979,19.36761c.55248,2.14765-.31449,4.36454-2.37642,6.08293A13.81231,13.81231,0,0,1,403.33253,756.2532Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3f3d56"
        />
        <path
          d="M523.96291,755.69264l-19.83215.54763a13.81231,13.81231,0,0,1-8.48582-2.37891c-2.15362-1.602-3.14159-3.76763-2.70848-5.94251l3.90259-19.61285c.73546-3.7,5.2696-6.49714,10.77993-6.6493l10.95246-.30244c5.5104-.15213,10.19188,2.39045,11.1305,6.04428l4.979,19.36762c.55246,2.14769-.31448,4.36453-2.37641,6.08292A13.81217,13.81217,0,0,1,523.96291,755.69264Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3f3d56"
        />
        <path
          d="M334.46727,589.18826a4.33143,4.33143,0,0,1,1.595-8.30761L561.53,574.65488a4.33,4.33,0,0,1,4.4488,4.208l0,.00038a4.33573,4.33573,0,0,1-4.20924,4.44844l-.00045-.00019-225.46776,6.22569A4.30673,4.30673,0,0,1,334.46727,589.18826Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3f3d56"
        />
        <rect
          x="356.21949"
          y="515.39594"
          width="64.47382"
          height="217.68039"
          transform="translate(-194.26833 -166.59855) rotate(-1.58175)"
          fill="#e6e6e6"
        />
        <polygon
          points="242.126 333.079 229.737 366.828 229.748 520.587 244.844 524.916 242.126 333.079"
          fill="#ccc"
        />
        <path
          d="M385.11593,457.6809c-12.05743.34244-21.25689,21.20913-21.03567,29.35186l.407,14.74161,43.65913-1.20557-.407-14.74161C407.51085,477.68465,397.17388,457.35745,385.11593,457.6809Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3f3d56"
        />
        <path
          d="M354.06345,606.43974a8.50984,8.50984,0,0,1-8.73142-8.26213l-.66249-23.99087a8.5,8.5,0,1,1,16.99353-.46923l.66248,23.99087A8.50982,8.50982,0,0,1,354.06345,606.43974Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3f3d56"
        />
        <polyline
          points="193.233 339.284 192.928 323.429 197.086 323.326 197.391 339.13 201.16 569.476 197.001 569.591"
          fill="#3f3d56"
        />
        <path
          d="M425.06668,553.91991l-74.73856,2.0638c-7.13852-8.70518-7.78692-45.7926,7.11089-52.85111l5.8423-9.92024,45.04258-2.19882,5.26415,10.44507C420.99871,507.7645,424.81991,544.62037,425.06668,553.91991Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#e6e6e6"
        />
        <path
          d="M396.9363,536.13244l-18.90007.52192c-3.64713.10071-6.74793-4.631-6.91128-10.54634s2.67166-10.811,6.3188-10.91168l18.90006-.52192c3.64714-.10071,6.74794,4.631,6.91129,10.54634S400.58344,536.03173,396.9363,536.13244Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3d5afe"
        />
        <polygon
          points="183.151 465.568 144.192 493.088 137.386 555.138 182.834 539.953 183.151 465.568"
          fill="#e6e6e6"
        />
        <rect
          x="480.62986"
          y="512.731"
          width="64.47382"
          height="217.68039"
          transform="translate(-194.14737 -163.16544) rotate(-1.58175)"
          fill="#e6e6e6"
        />
        <polygon
          points="301.996 333.806 315.257 367.222 319.236 520.93 304.257 525.649 301.996 333.806"
          fill="#ccc"
        />
        <ellipse
          cx="397.13212"
          cy="262.58361"
          rx="8.44737"
          ry="11.32747"
          fill="#e6e6e6"
        />
        <path
          d="M508.26852,455.05074c-12.05744.34245-21.25689,21.20914-21.03567,29.35186l.407,14.74161,43.65914-1.20557L530.892,483.197C530.66338,475.05448,520.32648,454.72726,508.26852,455.05074Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3f3d56"
        />
        <polyline
          points="343.567 344.247 343.262 328.392 347.42 328.288 347.725 344.093 351.494 574.439 347.335 574.554"
          fill="#3f3d56"
        />
        <path
          d="M548.21927,551.28975l-74.7385,2.0638c-1.30314-7.87683.3826-44.63292,7.11089-52.85111l5.8423-9.92024,45.04259-2.19882,5.26414,10.44507C550.0394,499.91218,554.69093,536.03162,548.21927,551.28975Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#e6e6e6"
        />
        <path
          d="M520.18032,536.813l-18.90007.52192c-3.64714.10071-6.76843-5.37372-6.957-12.20169s2.626-12.46635,6.273-12.56708l18.90007-.52192c3.64714-.10071,6.76843,5.37372,6.957,12.20169S523.8274,536.71222,520.18032,536.813Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3d5afe"
        />
        <polygon
          points="368.086 449.662 405.749 478.93 409.715 541.227 365.009 523.983 368.086 449.662"
          fill="#e6e6e6"
        />
        <path
          d="M534.75357,338.67976c-40.4127,0-86.85713-44.8462-127.94893-23.53858-124.415,64.51367-107.28857-43.43994-143.032-83.58887l1.17348-2.35367c27.05948,103.21821,51.543,267.6924,142.3988,83.20476,22.75922-46.21386,97.45512,25.21994,141.42331,23.11264l.0763,2.8291Q541.8795,338.68114,534.75357,338.67976Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#f2f2f2"
        />
        <path
          d="M631.75057,345.0259c-32.21551,24.39973-96.31582,16.69148-116.20791,58.48687-60.228,126.54525-111.754,30.14828-164.48777,19.72362l-.48561-2.58476c52.79008,4.37844,109.41447-2.4151,163.75111-19.64751,49.10434-15.57131,92.91452-38.73551,126.692-66.96179l1.76894,2.20919Q637.43193,340.72462,631.75057,345.0259Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#f2f2f2"
        />
        <ellipse
          cx="104.13212"
          cy="150.58361"
          rx="8.44737"
          ry="11.32747"
          fill="#e6e6e6"
        />
        <ellipse
          cx="366.13212"
          cy="58.58361"
          rx="8.44737"
          ry="11.32747"
          fill="#e6e6e6"
        />
        <path
          d="M358.656,385.18822c-3.862,2.57318-7.81469-3.55124-3.87848-6.0102C358.63937,376.605,362.59207,382.72945,358.656,385.18822Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#e6e6e6"
        />
        <path
          d="M501.656,184.18823c-3.862,2.57319-7.81469-3.55124-3.87848-6.01018C501.63937,175.605,505.59207,181.72944,501.656,184.18823Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#e6e6e6"
        />
        <polygon
          points="408.749 125.801 406.292 125.262 406.83 122.804 405.192 122.445 404.653 124.903 402.196 124.365 401.837 126.003 404.294 126.542 403.756 128.999 405.394 129.358 405.933 126.9 408.39 127.439 408.749 125.801"
          fill="#e6e6e6"
        />
        <circle cx="183.53494" cy="32.73714" r="10.70233" fill="#ff6584" />
        <path
          d="M439.00118,196.74951c-12.05744.34245-21.2569,21.20915-21.03568,29.35188l.407,14.74161,43.65913-1.20556-.40711-14.74162C461.396,216.75324,451.05913,196.42606,439.00118,196.74951Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3f3d56"
        />
        <path
          d="M464.99807,754.96821l-19.83215.54763a13.81233,13.81233,0,0,1-8.48582-2.37891c-2.15368-1.602-3.14165-3.76765-2.70848-5.94251l3.90259-19.61285c.73551-3.7,5.2696-6.49714,10.78-6.64927l10.95246-.30244c5.51039-.15213,10.19188,2.39044,11.1305,6.04427l4.9789,19.36752c.55246,2.14769-.31448,4.36453-2.37641,6.08292A13.81216,13.81216,0,0,1,464.99807,754.96821Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3f3d56"
        />
        <rect
          x="415.4532"
          y="291.40111"
          width="64.47382"
          height="440.01582"
          transform="translate(-191.13137 -165.0065) rotate(-1.58175)"
          fill="#e6e6e6"
        />
        <polyline
          points="270.868 325.949 270.563 310.094 274.721 309.991 275.026 325.795 278.795 556.141 274.636 556.256"
          fill="#3f3d56"
        />
        <path
          d="M486.53068,330.85065,399.20479,333.262c-1.52254-9.20342.447-52.15,8.30847-61.75224l2.15433-11.462,60.29654-1.66505,3.15478,11.17106C481.77751,276.9218,486.2423,319.98486,486.53068,330.85065Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#e6e6e6"
        />
        <path
          d="M471.87015,259.73526l-62.22262,1.7182c-2.29663.06335-4.1931-1.14245-4.23579-2.69339a1.95169,1.95169,0,0,1,.09665-.65848l9.66494-29.77518c.41385-1.275,2.06138-2.21137,3.98418-2.26446l41.26444-1.13939c1.9228-.05308,3.61946.79092,4.10307,2.04115l11.29335,29.19646c.57762,1.4933-.75639,3.04723-2.97964,3.47082A6.08056,6.08056,0,0,1,471.87015,259.73526Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#e6e6e6"
        />
        <path
          d="M415.78556,501.48553a7.62611,7.62611,0,0,1-1.58088-.915,5.604,5.604,0,0,1-2.37913-4.32149l-2.26085-81.87758c-.0931-3.38939,3.22545-6.24061,7.39767-6.356l55.70319-1.53841c4.17306-.11605,7.64291,2.54888,7.73709,5.93821l2.26073,81.878a5.60268,5.60268,0,0,1-2.13683,4.44594,8.50617,8.50617,0,0,1-5.2613,1.90983l-55.70234,1.53767A8.91985,8.91985,0,0,1,415.78556,501.48553Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3f3d56"
        />
        <path
          d="M451.438,254.46588l-18.90007.52192c-3.64714.1007-6.7311-4.01989-6.87367-9.18422s2.70926-9.44884,6.3564-9.54955l18.90007-.52192c3.64714-.10071,6.7311,4.01989,6.87367,9.18422S455.08518,254.36517,451.438,254.46588Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3d5afe"
        />
        <path
          d="M898.19394,740.08733h-22V580.21c18.40229-1.31952,32.23267-6.95472,32.23267-13.70722,0-7.732-18.13245-14-40.5-14s-40.5,6.268-40.5,14c0,6.81751,14.09991,12.49408,32.76733,13.74207V740.08733h-22a8,8,0,0,0,0,16h60a8,8,0,0,0,0-16Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#e6e6e6"
        />
        <polygon
          points="676.847 481.363 669.62 489.238 635.806 465.112 646.471 453.489 676.847 481.363"
          fill="#a0616a"
        />
        <path
          d="M835.5538,679.70713a13.32168,13.32168,0,0,0,4.33559,9.30746l.31814.296,9.47771-10.32853,13.82067-15.06363-10.20277-9.36667-1.84225,2.01241-.19237.20719-9.1817,4.343-1.94585.92483-.33295.15534-.73246,7.86477v.0074A13.28266,13.28266,0,0,0,835.5538,679.70713Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <polygon
          points="714.287 559.924 704.06 563.031 687.21 525.063 702.304 520.478 714.287 559.924"
          fill="#a0616a"
        />
        <path
          d="M863.98679,756.2017l.12581.42173,32.97573-10.01775-4.02487-13.251-2.60429.79166-.27375.08142-12.25956-1.12461-.36253-.02958-4.639,6.3998a13.41268,13.41268,0,0,0-8.93758,16.72833Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          id="ad16ec3e-8c70-4d69-a6df-f9bf45b483f6-239"
          data-name="Path 17"
          d="M807.14234,538.68707s-65.294,63.042-24.01623,82.55557h12.75817a84.503,84.503,0,0,1,12.61132-25.10752c1.20538-1.57743,5.72106-1.257,6.97511-2.79432,7.657-9.38674,15.95823-17.44824,21.69136-17.12816l-1.37151,43.558L833.571,635.30785c-1.16546,8.03186,31.7271,78.16146,31.7271,78.16146L885.9723,705.344s-21.53993-57.76213-21.79225-66.08937L881.44161,547.693Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M801.69759,541.79634a3.89,3.89,0,0,0,1.27259,1.15419,8.98975,8.98975,0,0,0,2.6709.99144c14.7011,3.33675,62.72574,10.25451,75.19244,6.9547,1.53153-.39951,2.53033-.96181,2.85587-1.70168,1.04323-2.33795.91-12.21517.296-24.14179-.111-2.2344-2.57476-4.65373-2.72269-6.99173-.10359-1.75347,2.10859-3.40335,1.99023-5.18645-.81387-12.05979-1.86447-24.34893-2.56735-32.11751-.4439-4.99408-.74727-8.11629-.74727-8.11629l7.58361-47.81746-.35512-.58452-.39951-.19973-24.45256-12.10422-1.33175-3.40336a5.26651,5.26651,0,0,0-4.87568-3.3368l-16.10687-.06656a5.249,5.249,0,0,0-4.2838,2.17516l-4.36522,6.04473-28.85472,18.49661.111.34031,13.53951,41.20306.06657,3.211v.0074l.5031,25.82125-1.39831,3.49959,1.57591,5.74136s-.72509,6.71053-4.60938,11.69726c-3.52177,4.528-8.00534,9.2483-10.03257,13.0808v.0074C801.12792,538.57055,800.75058,540.4054,801.69759,541.79634Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3d5afe"
        />
        <path
          id="ef5568b9-6037-4ec0-9cfc-fa22633e4b80-240"
          data-name="Path 18"
          d="M779.991,617.49777l3.49,4.40992s32.09783,22.4561,33.2,24.55472l5.0727,4.80008,12.274-13.1203-6.383-8.00038a50.1066,50.1066,0,0,0-19.22112-14.71558h0l-9.78231-8.67888Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M895.61028,556.69023l-5.84513-56.57768L888.22236,476.827l16.36037-2.95828s-.64826,16.64985,1.495,26.04387c2.115,9.2699.28058,55.53545.14989,56.77256a7.95977,7.95977,0,1,1-10.61731.00505Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#a0616a"
        />
        <path
          d="M876.128,471.0061l4.55755,9.75141,1.59073,3.396,4.2838,22.43266,2.13822,2.27139,1.84225,7.33208.86563-.17756,15.61117-3.2332,4.55014-.93965A8.64955,8.64955,0,0,0,909.999,501.015c-.31073-.28115-.56966-.5549-.5623-.81383.259-15.60377-.69547-44.096-9.31487-55.8228-2.73751-3.72154-4.38-7.42827-6.76978-10.0178-.00741-.00736-.00741-.01476-.01477-.01476l-5.81537-9.52206-.35512-.58452-.39951-.19973Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3d5afe"
        />
        <circle
          id="af7fa85c-211d-4c02-a612-cf38cfa53f1c"
          data-name="Ellipse 5"
          cx="672.12801"
          cy="202.13103"
          r="21.43064"
          fill="#a0616a"
        />
        <path
          d="M874.76076,375.07894c.62766-3.6014,1.11688-8.27006-.6812-12.4204-1.51934-3.49282-5.32013-6.76762-9.54488-6.32591a4.39885,4.39885,0,0,0-1.37264-4.39892,11.55527,11.55527,0,0,0-4.6882-2.20425c-3.971-1.16718-8.08439-2.37721-12.33157-2.39969-4.7667-.0259-8.9903,1.60706-11.58015,4.48623l-.0634.09312-.77645,1.85365a1.86806,1.86806,0,0,1-1.14868,1.05728,1.83578,1.83578,0,0,1-1.55348-.1902,1.69462,1.69462,0,0,0-2.58087,1.36572l-.04131.791-1.112-.44327a1.68958,1.68958,0,0,0-2.29858,1.85051l.148.941a1.811,1.811,0,0,0-1.37993.31109,1.65526,1.65526,0,0,0-.58927,1.86,15.3983,15.3983,0,0,0,4.28388,6.39952,12.80237,12.80237,0,0,0,2.90823,1.97737q.32943.16611.68487.3146a76.25188,76.25188,0,0,0,26.96328,6.10778,15.65361,15.65361,0,0,0-1.67438,5.37209,5.87473,5.87473,0,0,0,2.514,5.54144,7.39947,7.39947,0,0,0,1.204.55162,7.94136,7.94136,0,0,1,1.15454.53448,3.59829,3.59829,0,0,1-.14825,6.24292s1.59493,1.94715,1.85017,1.91406a4.98167,4.98167,0,0,0,2.625-1.55306C870.17275,390.2022,873.27793,383.60175,874.76076,375.07894Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M968.58324,706.35184l.93143-20.94324a67.6402,67.6402,0,0,1,31.54965-7.98643c-15.15276,12.3885-13.25912,36.26927-23.53233,52.92887a40.68576,40.68576,0,0,1-29.83528,18.80144l-12.68037,7.76378a68.17644,68.17644,0,0,1,14.36987-55.24593A65.85415,65.85415,0,0,1,961.489,690.42482C964.526,698.4327,968.58324,706.35184,968.58324,706.35184Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#f2f2f2"
        />
        <path
          d="M1032.34212,757.91264a1.5486,1.5486,0,0,1-1.55329,1.55322H178.73865a1.55322,1.55322,0,0,1,0-3.10644h852.05018A1.54866,1.54866,0,0,1,1032.34212,757.91264Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#ccc"
        />
        <path
          d="M543.68349,601.12509a8.50985,8.50985,0,0,1-8.73142-8.26214l-.66249-23.99087a8.5,8.5,0,0,1,16.99351-.4692l.66249,23.99087A8.50985,8.50985,0,0,1,543.68349,601.12509Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3f3d56"
        />
        <path
          d="M918.53992,688.80705q-19.36817,0-42.45654-1.46386c-79.61377-5.05567-182.7378-22.63282-290.376-49.49219-107.6377-26.85938-206.93018-59.79395-279.58545-92.73438-35.39258-16.04687-62.55615-31.23633-80.73535-45.14746-19.24707-14.727-27.666-27.564-25.02393-38.15283,5.148-20.62891,50.731-25.14209,88.064-25.29541l.01269,3c-51.26806.21045-81.51367,8.38672-85.16552,23.022-4.65186,18.64014,33.28662,47.74073,104.08691,79.84131,72.49561,32.86813,171.60547,65.73923,279.07321,92.55661,107.46729,26.81641,210.40137,44.36328,289.83985,49.4082,77.57861,4.92774,124.74316-2.9375,129.39453-21.57812,3.86181-15.47656-21.59815-38.27539-71.68946-64.19824l1.37891-2.66407c36.397,18.83594,78.64551,45.85157,73.22168,67.58887-2.64252,10.58887-16.10541,17.96485-40.01508,21.92285C954.93494,687.67619,938.19422,688.80705,918.53992,688.80705Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#ccc"
        />
        <path
          d="M233.244,669.1541c-10.24609,0-16.90722-2.5459-19.85547-7.60742-5.814-9.98047,3.92334-27.49317,28.94092-52.05079l2.10156,2.14063c-23.23877,22.8125-33.34277,40.001-28.45068,48.40039,3.0957,5.3125,12.30859,7.2168,26.64844,5.50391,14.23535-1.69922,33.10058-6.76465,56.07177-15.05469,47.291-17.06738,106.62256-45.85645,167.065-81.06445,60.44043-35.207,114.75048-72.61621,152.92675-105.335,7.33252-6.28565,13.98731-12.33252,19.7793-17.9712a196.95978,196.95978,0,0,0,20.97559-23.37744c8.563-11.627,11.45312-20.58105,8.35791-25.89453-5.16992-8.87451-26.8877-8.04-61.15625,2.353l-.87012-2.8711c36.76611-11.14892,58.50732-11.48339,64.61865-.99169,3.79737,6.51855.92578,16.3374-8.53418,29.18359a199.92306,199.92306,0,0,1-21.29883,23.74756c-5.83691,5.6831-12.53906,11.77246-19.91992,18.09961-38.30957,32.83349-92.77685,70.354-153.36914,105.64892-60.59375,35.29688-120.1001,64.168-167.55615,81.29492-23.18506,8.36719-42.27344,13.48536-56.73486,15.21094A82.69758,82.69758,0,0,1,233.244,669.1541Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#ccc"
        />
        <path
          d="M780.78711,545.98171c-7.41429-.1477-47.23975-1.29075-53.77054-1.00966-.36944-1.29329-12.74985,14.57255-13.05093,14.6709a1.80832,1.80832,0,0,0,.60481,2.90758c.72777.44213,64.93165-.7456,65.70956-.59063a1.78591,1.78591,0,0,0,1.59378-1.03833l.42913-.96116c.04459-1.64432.58292-10.048.09966-11.48483A1.77814,1.77814,0,0,0,780.78711,545.98171Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#e6e6e6"
        />
        <path
          d="M782.30283,559.95889c-.37681.90549-.85882,2.04315-2.02291,2.00138l-65.03159.7298a1.79391,1.79391,0,0,1-1.56528-2.63308Z"
          transform="translate(-177.18543 -177.55905)"
          opacity="0.17"
        />
        <path
          d="M727.32042,544.77735c-.7-1.21052-18.08617-87.23831-19.53728-87.95769l-11.87031-14.52105-1.06095-.79779c-1.47534-.81046-5.01435-.26248-4.69563,1.99086h0c2.7887,13.37007,20.7256,89.51848,21.11867,101.39039l2.15914,15.12429a1.86251,1.86251,0,0,0,2.29966,1.52982,3.58266,3.58266,0,0,0,2.87739-2.07911C718.773,558.92333,728.13321,545.08723,727.32042,544.77735Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#e6e6e6"
        />
        <path
          d="M695.91,442.29922l22.70339,117.16093c-.75925,1.92172-4.312,3.268-5.13948.74717-.01446-.00347-23.29114-116.64351-23.31482-116.71323C689.911,440.687,694.54951,440.59792,695.91,442.29922Z"
          transform="translate(-177.18543 -177.55905)"
          opacity="0.17"
        />
        <polygon
          points="519.409 269.531 530.939 285.603 543.881 347.068 537.865 352.316 519.409 269.531"
          fill="#3d5afe"
        />
        <path
          d="M773.34421,554.26935l3.19.13a12.53882,12.53882,0,0,1,.46-2.76c.48-2.28.91-4.99-1.08-4.98-1.35-.13-1.81,1.58-2.05,3.44A26.31787,26.31787,0,0,1,773.34421,554.26935Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M755.04423,549.97937l2.15.07995,1.04.04c-.02-1.14.51-3.49-.78-4.07a1.60015,1.60015,0,0,0-.65-.13c-1.21-.07-1.57.85-1.68006,1.91-.06994.64-.04,1.32-.06,1.85C755.06418,549.76935,755.05424,549.87933,755.04423,549.97937Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M748.67423,549.71936l2.16.09,1.03.04c-.02-1.31.67-4.17-1.43005-4.21-1.94-.11-1.69,2.36-1.74,3.76C748.69419,549.50934,748.68418,549.61932,748.67423,549.71936Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M742.30424,549.46936l2.15.08,1.04.04c-.02-1.3.67-4.16-1.43-4.2-1.94-.12-1.69,2.35-1.74,3.76C742.32419,549.25934,742.31418,549.36932,742.30424,549.46936Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M735.93418,549.20935l2.16.09,1.03.04c-.02-1.31.67-4.16-1.43-4.21-1.94-.11-1.69,2.36005-1.74,3.76C735.9542,548.99933,735.94419,549.10937,735.93418,549.20935Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M729.56418,548.95935l2.15.08,1.04.04c-.02-1.2.56-3.71-.98-4.14h-.01a1.46394,1.46394,0,0,0-.44-.06,1.5056,1.5056,0,0,0-.54.05h-.01c-1.34.35-1.15,2.45-1.19,3.71C729.5842,548.74933,729.57419,548.85937,729.56418,548.95935Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M766.91422,548.27936l3.19.13c0-.12,0-.25.01-.4a13.33041,13.33041,0,0,0,.04-2.29c-.11-.84-.47-1.5-1.48-1.52-1.03-.06-1.44.6-1.6,1.45a3.11646,3.11646,0,0,0-.08.44v.01C766.91422,546.87933,766.96421,547.71936,766.91422,548.27936Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M760.54423,548.01935l3.19.13a23.28044,23.28044,0,0,0,.06-2.58c-.09-.88-.44-1.6-1.49-1.62-1.06006-.07-1.47.65-1.63,1.54C760.52421,546.34936,760.60422,547.37933,760.54423,548.01935Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M754.17423,547.76935l.95.04,2.24.09c-.01-.48.08-1.18.09-1.87a4.23315,4.23315,0,0,0-.02-.62c-.08-.93-.41-1.7-1.5-1.72-1.11-.06-1.5.71-1.63995,1.65C754.15421,546.17932,754.23423,547.14935,754.17423,547.76935Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M766.97422,554.01935l3.19.12c-.01-1.3.67-4.16-1.43-4.2C766.63419,549.80932,767.09421,552.70935,766.97422,554.01935Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M760.60422,553.75934l3.19.13c-.01-1.31.67-4.17-1.43-4.21C760.2642,549.55932,760.72422,552.45935,760.60422,553.75934Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M754.23423,553.50934l3.19.12c-.01-.93.34-2.65-.23-3.57a1.31959,1.31959,0,0,0-1.2-.63,1.40554,1.40554,0,0,0-.93.23C754.04423,550.32934,754.3342,552.44934,754.23423,553.50934Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M747.86423,553.24933l3.19.13c-.01-.93.32995-2.63995-.22-3.57a1.32343,1.32343,0,0,0-1.21-.63995,1.38934,1.38934,0,0,0-.93.23C747.67423,550.08935,747.96421,552.19934,747.86423,553.24933Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M741.49418,552.99933l3.19.12c-.01-.93.34-2.65-.23-3.56994a1.31934,1.31934,0,0,0-1.2-.63,1.40519,1.40519,0,0,0-.93.23C741.30424,549.81933,741.59421,551.93933,741.49418,552.99933Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M735.12418,552.73938l3.19.12994c-.00994-.93.33-2.63995-.22-3.56994a1.31238,1.31238,0,0,0-1.21-.64,1.38925,1.38925,0,0,0-.93.23C734.93418,549.57934,735.22422,551.68933,735.12418,552.73938Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M728.75419,552.48938l3.19.12c-.01-.93005.33-2.64-.23-3.57a1.30821,1.30821,0,0,0-1.2-.63,1.405,1.405,0,0,0-.93.23C728.56418,549.31933,728.85422,551.42932,728.75419,552.48938Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#2f2e41"
        />
        <path
          d="M764.7803,543.24528l24.31306-51.42056L798.286,470.375l-14.44775-8.22653s-4.92111,15.91917-10.06408,24.06724c-5.07509,8.04038-18.71837,52.28659-19.00618,53.49683a7.95977,7.95977,0,1,0,10.01233,3.53276Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#a0616a"
        />
        <path
          d="M764.63224,495.6377l18.38757,11.09293,4.17-6.30274,2.77738-1.43257,11.49437-19.73725,10.16248-10.35367,13.42726-42.9193-17.47011,2.621-.02283.00749c-3.11234,1.65072-5.89632,4.60186-9.71281,7.20274-12.02605,8.19551-22.39546,34.75188-27.3355,49.55869-.08107.243-.4139.41243-.80035.57792a8.653,8.653,0,0,0-5.0775,9.68473Z"
          transform="translate(-177.18543 -177.55905)"
          fill="#3d5afe"
        />
      </svg>
    </div>
  );
};

export default SpeedView;
