import { CurtainsRounded, PanToolAlt } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { MasterView } from "../MasterView";

export const UpListView = ({ children }) => {
  return (
    <Container sx={{ margin: "50px 0" }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={6} xs={12} style={{ textAlign: "center" }}>
          <Typography variant="h1" style={{ fontWeight: 700, opacity: 0.4 }}>
            <div style={{ display: "inline-block" }}>
              <PanToolAlt fontSize="30px" />
            </div>
          </Typography>
          <Typography variant="body2" gutterBottom style={{ fontWeight: 700 }}>
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
