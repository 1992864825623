import React, { useState, useEffect } from "react";
import { IndexView } from "../views/main/IndexView";
import { useCookies } from "react-cookie";
import {
  CookieValues,
  LinkValues,
  ResponseValues,
} from "../values/ConstantValues";
import { PanelView } from "../views/main/PanelView";
import { PanelConfigView } from "../views/main/PanelConfigView";
import { useNavigate } from "react-router-dom";
import { RequestProvider } from "../providers/RequestProvider";

export const MainController = () => {
  const [cookies, setCookie] = useCookies([CookieValues.userCredential]);

  return (
    (!cookies.hasOwnProperty(CookieValues.userCredential) && <IndexView />) || (
      <PanelController />
    )
  );
};

export const PanelController = () => {
  const [isShowingConfig, setIsShowingConfig] = useState(false)
  
  const OnShowConfigClicked = () => {
    setIsShowingConfig(!isShowingConfig)
  }
  
  const OnAndroidClicked = () => {
    window.location.href = "/learn/android/vipino"
  }

  return (<>
  {isShowingConfig && <PanelConfigController /> ||(
    <PanelConfigView
      onShowConfigClicked={OnShowConfigClicked}
      onAndroidClicked={OnAndroidClicked}
    />)}
    </>
  );
};


export const PanelConfigController = () => {
  const [elapsedTime, setElapsedTime] = useState(null);
  const [protocolList, setProtocolList] = useState(null);
  const [configList, setConfigList] = useState(null);
  const [operatorList, setOperatorList] = useState(null);
  const [selectedProtocol, setSelectedProtocol] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [isPendingConfig, setIsPendingConfig] = useState(null);
  const [isPendigOperator, setIsPendigOperator] = useState(true);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([CookieValues.userCredential]);
  const request = new RequestProvider({
    "USER-TOKEN": cookies[CookieValues.userCredential],
  });

  useEffect(() => {
    if (elapsedTime === null) {
      request.get(
        LinkValues.backend.account.user.elapsed(),
        null,
        (response) => {
          if (response.status == 200 && response.data.hasOwnProperty("code")) {
            if (response.data.code == ResponseValues.code.ok) {
              setElapsedTime([
                response.data.data.elapsed,
                response.data.data.expired_at,
              ]);
              return;
            }
          }
          setElapsedTime([0, 100]);
        },
        () => {
          setElapsedTime([0, 100]);
        }
      );
    }
  }, [elapsedTime]);

  // Start protocol section
  useEffect(() => {
    if (protocolList === null) {
      request.get(
        LinkValues.backend.servers.protocol.list(),
        null,
        (response) => {
          if (response.status == 200 && response.data.hasOwnProperty("code")) {
            if (response.data.code == ResponseValues.code.ok) {
              setProtocolList(response.data.data);
              return;
            }
          }
          setProtocolList([]);
        }
      );
    } else if (
      selectedProtocol === null &&
      protocolList &&
      protocolList.length > 0
    ) {
      setSelectedProtocol(protocolList[0].code);
    }
  }, [protocolList]);

  useEffect(() => {
    if (selectedProtocol !== null && selectedOperator !== null) {
      if (selectedLocation !== null && selectedLocation.length > 0) {
        setSelectedLocation("");
      } else {
        setIsPendingConfig(true);
      }
    }
  }, [selectedProtocol, selectedOperator]);
  // End protocol section

  // Start operator section
  useEffect(() => {
    if (operatorList === null) {
      request.get(
        LinkValues.backend.servers.operator.list.user(),
        null,
        (response) => {
          if (response.status == 200 && response.data.hasOwnProperty("code")) {
            if (response.data.code == ResponseValues.code.ok) {
              setOperatorList(response.data.data);
              return;
            }
          }
          setOperatorList([]);
        }
      );
    } else if (
      selectedOperator === null &&
      operatorList &&
      operatorList.length > 0
    ) {
      setSelectedOperator(operatorList[0].code);
    }
  }, [operatorList]);

  useEffect(() => {
    if (selectedLocation !== null) {
      setIsPendingConfig(true);
    }
  }, [selectedLocation]);
  // End location section

  // Start config section
  useEffect(() => {
    if (isPendingConfig === true) {
      var params = {
        code: selectedProtocol,
        operator: selectedOperator,
      };
      if (selectedLocation !== null && selectedLocation.length > 0)
        params["location"] = selectedLocation;
      FetchServerList(params);
    }
  }, [isPendingConfig]);

  useEffect(() => {
    if (configList !== null) {
      setIsPendingConfig(false);
    }
  }, [configList]);
  // End config section

  const FetchServerList = (params) => {
    request.get(
      LinkValues.backend.servers.list._self(),
      { ...params },
      (response) => {
        if (response.status == 200 && response.data.hasOwnProperty("code")) {
          if (response.data.code == ResponseValues.code.ok) {
            if (response.data.data.length > 0)
              setConfigList(response.data.data[0]);
            else setConfigList(response.data.data);
            return;
          }
        }
        setConfigList([]);
      }
    );
  };

  const onChangeLocation = (code) => {
    setSelectedLocation(code);
  };

  const onChangeOperator = (code) => {
    setSelectedOperator(code);
  };

  const handlerClickBuy = () => {
    navigate("/pricing");
  };

  const handlerOnProtocolSelect = (code) => {
    setSelectedProtocol(code);
  };

  return (
    <PanelView
      protocolList={protocolList}
      elapsedTime={elapsedTime}
      selectedProtocol={selectedProtocol}
      configList={configList}
      handlerClickBuy={handlerClickBuy}
      handlerOnProtocolSelect={handlerOnProtocolSelect}
      isPendingConfig={isPendingConfig}
      isPendigOperator={isPendigOperator}
      operatorList={operatorList}
      selectedOperator={selectedOperator}
      onChangeLocation={onChangeLocation}
      onChangeOperator={onChangeOperator}
    />
  );
};
