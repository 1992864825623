import { Android, Apple, DesktopWindows } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { MasterView } from "../MasterView";

export const IndexView = () => {
  const navigate = (url) => {
    window.open(url, "_self", "noreferrer");
  };
  return (
    <MasterView marginY={70}>
      <Container>
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            {VipinoView(navigate)}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            {AnyConnectView(navigate)}
          </Grid>
          {/* 
          <Grid item lg={4} md={6} xs={12}>
            {OpenConnectView(navigate)}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            {V2RayView(navigate)}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            {OneClickView(navigate)}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            {NetchView(navigate)}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            {PPTPView(navigate)}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            {IPSecView(navigate)}
          </Grid> */}
        </Grid>
      </Container>
    </MasterView>
  );
};

const V2RayView = (navigate) => {
  return (
    <div className="containerBlock">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/v2rayng.png"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              آموزش V2RayNG
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: Vmess, VLESS, Trojan, Socks, Shadowsocks
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/learn/android/v2rayng");
              }}
              fullWidth
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const OneClickView = (navigate) => {
  return (
    <div className="containerBlock ">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/oneclick.png"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              آموزش OneClick
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: Vmess, VLESS, Trojan, Socks, Shadowsocks
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/learn/android/oneclick");
              }}
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/learn/ios/oneclick");
              }}
            >
              <Apple
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              آیفون
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const NetchView = (navigate) => {
  return (
    <div className="containerBlock ">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/netch.jpeg"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              آموزش Netch
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: Vmess, VLESS, Trojan, SOCKS5, Shadowsocks, Wireguard
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("https://github.com/netchx/netch/releases/");
              }}
            >
              <DesktopWindows
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              ویندوز
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const OpenConnectView = (navigate) => {
  return (
    <div className="containerBlock ">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/openconnect.jpeg"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              آموزش OpenConnect
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: Cisco OpenConnect
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/learn/android/coc");
              }}
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/learn/ios/coc");
              }}
            >
              <Apple
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              آیفون
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const AnyConnectView = (navigate) => {
  return (
    <div className="containerBlock ">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/anyconnect.png"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              آموزش AnyConnect
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: Cisco AnyConnect
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          {/* <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/learn/android/cac");
              }}
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid> */}
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/learn/ios/cac");
              }}
            >
              <Apple
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              آیفون
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/learn/win/cac");
              }}
            >
              <DesktopWindows
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              ویندوز
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const VipinoView = (navigate) => {
  return (
    <div className="containerBlock ">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/vipino.png"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              آموزش اپلیکیشن وی‌پی‌نو
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
            * برای اندروید 6 به بالا
            <br />
            پشتیبانی از همه پروتکل های فعال
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/learn/android/vipino");
              }}
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const PPTPView = (navigate) => {
  return (
    <div className="containerBlock ">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/pptp.jpg"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              آموزش PPTP
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: PPTP
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/learn/android/pptp");
              }}
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const IPSecView = (navigate) => {
  return (
    <div className="containerBlock">
      <Grid container spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item xs="auto">
            <img
              src="/assets/apps/ipsec.jpg"
              width={70}
              style={{ borderRadius: "5px", border: "1px solid #ddd" }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              آموزش IPSec
            </Typography>
            <hr />
            <Typography
              className="downloadXSize"
              variant="caption"
              gutterBottom
              fontWeight={700}
            >
              پروتکل: IPSec
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/learn/android/ipsec");
              }}
            >
              <Android
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              اندروید
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/learn/ios/ipsec");
              }}
            >
              <Apple
                style={{
                  width: "18px",
                }}
              />
              <span
                style={{
                  padding: 2,
                }}
              ></span>
              آیفون
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
