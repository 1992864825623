export const AppFonts = `
@font-face {
    font-family: iranyekanfa;
    font-style: normal;
    font-weight: bold;
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebboldfanum.eot");
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebboldfanum.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */
        url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebboldfanum.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebboldfanum.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: iranyekanfa;
    font-style: normal;
    font-weight: 100;
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebthinfanum.eot");
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebthinfanum.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */
        url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebthinfanum.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebthinfanum.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: iranyekanfa;
    font-style: normal;
    font-weight: 300;
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanweblightfanum.eot");
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanweblightfanum.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */
        url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanweblightfanum.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanweblightfanum.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: iranyekanfa;
    font-style: normal;
    font-weight: normal;
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebregularfanum.eot");
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebregularfanum.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */
        url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebregularfanum.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebregularfanum.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: iranyekanfa;
    font-style: normal;
    font-weight: 500;
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebmediumfanum.eot");
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebmediumfanum.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */
        url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebmediumfanum.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebmediumfanum.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: iranyekanfa;
    font-style: normal;
    font-weight: 800;
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebextraboldfanum.eot");
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebextraboldfanum.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */
        url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebextraboldfanum.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebextraboldfanum.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: iranyekanfa;
    font-style: normal;
    font-weight: 850;
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebblackfanum.eot");
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebblackfanum.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */
        url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebblackfanum.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebblackfanum.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: iranyekanfa;
    font-style: normal;
    font-weight: 900;
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebextrablackfanum.eot");
    src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebextrablackfanum.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-8 */
        url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebextrablackfanum.woff")
        format("woff"),
      /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebextrablackfanum.ttf")
        format("truetype");
  }
  
  `;

export const AppTheme = {
  direction: "rtl",
  typography: {
    fontFamily: "iranyekanfa !important",
  },
  palette: {
    primary: {
      main: "#2e46cf",
    },
    secondary: {
      main: "#eee",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: AppFonts,
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: "#fff",
        },
      },
    },
  },
};

export const AppStyles = {
  classes: (theme) => {
    return {
      appbarBox: {
        display: "flex",
        alignItems: "center",
        "& img": {
          height: 36,
        },
      },
    };
  },
  typography: {
    fontFamily: "iranyekan, Roboto",
    button: {
      textTransform: "none",
    },
    navBar: {
      fontFamily: "iranyekan, Roboto",
      fontSize: 13,
      fontWeight: 400,
    },
    title: {
      fontFamily: "iranyekan, Roboto",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 2,
    },
    text: {
      fontFamily: "iranyekan, Roboto",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: 2,
    },
    caption: {
      fontFamily: "iranyekan, Roboto",
      fontSize: 12,
      fontWeight: 500,
      opacity: "60%",
      lineHeight: 2,
    },
    staticWhite: {
      fontFamily: "iranyekan, Roboto",
      fontSize: 12,
      fontWeight: 500,
      color: "#fff",
    },
    h6: {
      fontWeight: 800,
      fontSize: "1.15rem",
    },
    h5: {
      fontWeight: 800,
    },
  },
  palette: {
    light: {
      theme: {
        main: "#fff",
        contrastText: "#000",
      },
      primary: {
        main: "#F8C711",
        contrastText: "#000",
      },
      secondary: {
        main: "#F2F2F2",
        contrastText: "#000",
      },
      secondaryFader: {
        main: "#F9F9F9",
        contrastText: "#000",
      },
    },
    dark: {
      theme: {
        main: "#1c1c1c",
        contrastText: "#fff",
      },
      primary: {
        main: "#F8C711",
        contrastText: "#000",
      },
      secondary: {
        main: "#222222",
        contrastText: "#fff",
      },
      secondaryFader: {
        main: "#191919",
        contrastText: "#fff",
      },
    },
  },
};
