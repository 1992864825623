import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { MasterView } from "../MasterView";

export const IndexView = () => {
  return (
    <MasterView marginY={150}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} xs={12} style={{ textAlign: "center" }}>
            <Typography
              variant="h1"
              gutterBottom
              style={{ fontWeight: 700, opacity: 0.4 }}
            >
              <div style={{ transform: "scaleX(-1)", display: "inline-block" }}>
                4
              </div>
              40
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ fontWeight: 700 }}
            >
              متاسفانه صفحه ای که به دنبال آن هستید وجود ندارد. می توانید به{" "}
              <Typography variant="body2" component="a" href="/">
                صفحه اصلی
              </Typography>{" "}
              بروید.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};
