import { Android, Apple, DesktopWindows } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { MasterView } from "../MasterView";

export const AndroidIPSecView = () => {
  return (
    <MasterView marginY={70}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} xs={12}>
            <Typography variant="h6" gutterBottom>
              1. تنظیم کانفیگ و اتصال
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ fontWeight: 700 }}
            >
              مطابق تصویر زیر پروتکل IPSec را تنظیم کنید
            </Typography>
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <img src="/assets/learn/ipsec-apk.png" />
            </div>
            <br />
            <hr />
            <br />
            <Typography variant="h6" gutterBottom>
              2. اتصال با موفقیت انجام شد
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};
