import { Button, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../common/ButtonView";
import { FooterView } from "../common/FooterView";
import { HeaderView } from "../common/HeaderView";
import { MasterView } from "../MasterView";
import PlatformView from "../styles/PlatformView";
import SpeedView from "../styles/SpeedView";

export const IndexView = () => {
  const navigate = useNavigate();
  return (
    <MasterView>
      <HeaderView />

      <Box className="mainBlockBox">
        <Container>
          <Grid container spacing={2}>
            <Grid
              container
              item
              md={5}
              xs={12}
              order={{ md: 1, xs: 2 }}
              direction="column"
              justifyContent="center"
              alignItems="start"
            >
              <Typography variant="h5" gutterBottom style={{ fontWeight: 700 }}>
                سازگار با تمام پلتفرم ها...
              </Typography>
              <br />
              <Typography
                variant="body1"
                gutterBottom
                style={{ textAlign: "justify" }}
              >
                ما سرورها و ابزارهای مورد نیاز جهت اتصال ایمن به اینترنت جهانی
                رو ارائه میدیم. برای استفاده از این ابزار ها می تونی اپلیکیشن
                مورد نیاز رو برای هر سیستم عاملی که داری تهیه کنی و به اطلاعات
                آزاد دسترسی داشته باشی!
              </Typography>
              {/* <br />
              <ButtonPrimary
                onClick={() => {
                  navigate("/download");
                }}
              >
                دریافت اپلیکیشن
              </ButtonPrimary> */}
            </Grid>
            <Grid item md={7} xs={12} order={{ md: 2, xs: 1 }}>
              <PlatformView padding={50} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="mainBlockBox">
        <Container>
          <Grid container spacing={2}>
            <Grid item md={7} xs={12}>
              <SpeedView padding={50} />
            </Grid>
            <Grid
              container
              item
              md={5}
              xs={12}
              direction="column"
              justifyContent="center"
              alignItems="start"
            >
              <Typography variant="h5" gutterBottom style={{ fontWeight: 700 }}>
                بالاترین سرعت ممکن رو تجربه کن!
              </Typography>
              <br />
              <Typography
                variant="body1"
                gutterBottom
                style={{ textAlign: "justify" }}
              >
                <b>وی‌پی‌نو</b> با همکاری با بهترین ارائه دهندگان خدمات پردازش
                ابری و دیتاسنتر ها و با تهیه بروزترین و قویترین سرورها، ارتباط
                شما رو با اینترنت جهانی با بالاترین سرعت برقرار می کنه!
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container>
        <Box className="mainPartners">
          <img src="/assets/linode.png" />
          <img src="/assets/hostinger.png" />
          <img src="/assets/hetzner.png" />
        </Box>
      </Container>
      <FooterView />
    </MasterView>
  );
};
