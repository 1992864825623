import { AllOut, FlashOn } from "@mui/icons-material";
import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "./ButtonView";

export const FooterView = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <Container>
        <Grid container>
          <Grid container item xs direction="row" alignItems="center">
            <FlashOn sx={{ ml: 1 }} /> <b>وی‌پی‌نو</b>
            <span style={{ margin: "0 10px" }}> - </span>فراتر از محدودیت ها...
          </Grid>
          {/* <Grid item>
            <ButtonPrimary
              onClick={() => {
                navigate("/download");
              }}
            >
              دریافت اپلیکیشن
            </ButtonPrimary>
          </Grid> */}
        </Grid>
        <Box flexGrow={1}></Box>
      </Container>
    </div>
  );
};
