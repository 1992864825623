import { ErrorOutline } from "@mui/icons-material";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { EmptyListView } from "../error/EmptyListView";
import { MasterView } from "../MasterView";

export const EmailView = ({
  isPending = false,
  errorMessage,
  emailFieldValue,
  emailFieldOnChange,
  onSubmit,
}) => {
  return (
    <MasterView marginY={70}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} xs={12}>
            <div className="containerBlock">
              <Typography
                variant="h5"
                gutterBottom
                fontWeight={700}
                sx={{ margin: "20px 0" }}
                textAlign="center"
              >
                ادامه فرایند خرید
              </Typography>
              <Alert
                severity="warning"
                icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
                sx={{ marginBottom: "12px" }}
              >
                در صورت بروز هرگونه مشکل در پرداخت با آیدی تلگرام "
                <a href="https://t.me/vipino_2">@vipino_2</a>" .در ارتباط باشید
              </Alert>
              <Alert
                severity="info"
                icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
                sx={{ marginBottom: "12px" }}
              >
                پس از پرداخت، اطلاعات ورود به حساب به شما نمایش داده می شود.
                همچنین این اطلاعات به ایمیل شما ارسال می شود. در صورت عدم مشاهده
                اطلاعات حساب، پوشه اسپم ایمیل خود را بررسی کنید.
              </Alert>
              {errorMessage && (
                <Alert
                  severity="error"
                  icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
                  sx={{ marginBottom: "12px" }}
                >
                  {errorMessage}
                </Alert>
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                <Grid container direction="column" spacing={4}>
                  <Grid item>
                    <TextField
                      id="standard-basic"
                      label="ایمیل"
                      variant="standard"
                      value={emailFieldValue}
                      onChange={emailFieldOnChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSubmit}
                      disabled={isPending ? true : false}
                      type="submit"
                      fullWidth
                    >
                      خرید
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};
