import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { RequestProvider } from "../providers/RequestProvider";
import {
  CookieValues,
  LinkValues,
  ResponseValues,
} from "../values/ConstantValues";
import { IndexView } from "../views/pricing/IndexView";
import { EmailView } from "../views/pricing/EmailView";

export const PricingController = () => {
  const [planList, setPlanList] = useState(null);
  const [operatorList, setOperatorList] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [cookies, setCookie] = useCookies([CookieValues.userCredential]);
  const request = new RequestProvider();

  // Start operator section
  useEffect(() => {
    if (operatorList === null) {
      request.get(
        LinkValues.backend.servers.operator.list._self(),
        null,
        (response) => {
          if (response.status == 200 && response.data.hasOwnProperty("code")) {
            if (response.data.code == ResponseValues.code.ok) {
              setOperatorList(response.data.data);
              return;
            }
          }
          setOperatorList([]);
        }
      );
    }
  }, [operatorList]);

  useEffect(() => {
    if (selectedOperator !== null) {
      setPlanList(null);
    }
  }, [selectedOperator]);
  // End operator section

  useEffect(() => {
    if (planList === null) {
      request.get(
        LinkValues.backend.shop.product.list(),
        {
          operator: selectedOperator,
        },
        (response) => {
          if (response.status == 200 && response.data.hasOwnProperty("code")) {
            if (response.data.code == ResponseValues.code.ok) {
              setPlanList(response.data.data);
              return;
            }
          }
          setPlanList([]);
        }
      );
    }
  }, [planList]);

  const onSubmit_plan = (code) => {
    if (!cookies || !cookies.hasOwnProperty(CookieValues.userCredential)) {
      navigate(`/pricing/confirm/${code}`);
    } else {
      request.post(
        LinkValues.backend.shop.order.buy(),
        {
          code: code,
          type: "x",
        },
        (response) => {
          if (response.status == 200 && response.data.hasOwnProperty("code")) {
            if (response.data.code == ResponseValues.code.ok) {
              window.location.href = response.data.data;
            } else {
              setErrorMessage(response.data.message);
              setIsPending(false);
            }
          }
        },
        (response) => {
          setErrorMessage(response.response.data.message);
          setIsPending(false);
        },
        null,
        {
          "USER-TOKEN": cookies[CookieValues.userCredential],
        }
      );
    }
  };

  const onChange_Operator = (code) => {
    setSelectedOperator(code);
  };

  return (
    <IndexView
      isPending={isPending}
      errorMessage={errorMessage}
      planList={planList}
      operatorList={operatorList}
      selectedOperator={selectedOperator}
      onChange_Operator={onChange_Operator}
      onSubmit_Plan={onSubmit_plan}
    />
  );
};

export const PricingEmailController = () => {
  let { code } = useParams();
  const [isPending, setIsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const request = new RequestProvider();

  const handlerEmailFieldOnChange = (e) => {
    setEmailAddress(e.target.value);
  };
  const handleOnSubmit = () => {
    setIsPending(true);
    request.post(
      LinkValues.backend.shop.order.buy(),
      {
        code: code,
        email: emailAddress,
        type: "x",
      },
      (response) => {
        if (response.status == 200 && response.data.hasOwnProperty("code")) {
          if (response.data.code == ResponseValues.code.ok) {
            window.location.href = response.data.data;
          } else {
            setErrorMessage(response.data.message);
            setIsPending(false);
          }
        }
      },
      (response) => {
        setErrorMessage(response.response.data.message);
        setIsPending(false);
      }
    );
  };

  return (
    <EmailView
      isPending={isPending}
      emailFieldValue={emailAddress}
      emailFieldOnChange={handlerEmailFieldOnChange}
      errorMessage={errorMessage}
      onSubmit={handleOnSubmit}
    />
  );
};
