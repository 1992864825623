import { Button, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { WorldMapView } from "../styles/WorldMapView";

export const HeaderView = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="header">
        <Container>
          <Grid container spacing={2}>
            <Grid container item xs={12} display={{ md: "none" }}>
              <Grid item xs={12}>
                <Button
                  sx={{
                    color: "#000",
                    border: "1px solid #ffc107",
                    background: "#ffc107",
                    borderRadius: 2,
                    padding: "5px 30px",
                    "&:hover": {
                      color: "#000",
                      border: "1px solid #ffc107",
                      background: "#ffc107",
                    },
                  }}
                  fullWidth
                  onClick={() => {
                    navigate("/pricing");
                  }}
                >
                  خرید
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ padding: "5px 0" }}></Grid>
              <Grid item xs={12}>
                <Button
                  sx={{
                    color: "#000",
                    border: "1px solid #ffc107",
                    background: "#ffc107",
                    borderRadius: 2,
                    padding: "5px 30px",
                    "&:hover": {
                      color: "#000",
                      border: "1px solid #ffc107",
                      background: "#ffc107",
                    },
                  }}
                  fullWidth
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  ورود
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ padding: "10px 0" }}></Grid>
            </Grid>
            <Grid
              container
              item
              md={4}
              xs={12}
              order={{ md: 1, xs: 2 }}
              direction="column"
              justifyContent="center"
              alignItems="start"
            >
              <Typography variant="h5" gutterBottom style={{ fontWeight: 700 }}>
                فراتر از محدودیت ها
              </Typography>
              <br />
              <Typography
                variant="body1"
                gutterBottom
                style={{ textAlign: "justify" }}
              >
                با <b>وی‌پی‌نو</b> هر لحظه و همه جا با بهترین سرعت به اینترنت
                دسترسی داشته باش. <b>وی‌پی‌نو</b> این امکان رو بهت میده که هویت
                خودت رو توی دنیای دیجیتال مخفی نگهداری یا از همه محدودیت هایی که
                روی اینترنت اعمال شده عبور کنی!
              </Typography>
              <br />
              <Grid container spacing={2} display={{ md: "flex", xs: "none" }}>
                <Grid item md={"auto"} xs={6}>
                  <Button
                    sx={{
                      color: "#000",
                      border: "1px solid #ffc107",
                      background: "#ffc107",
                      borderRadius: 2,
                      padding: "5px 30px",
                      "&:hover": {
                        color: "#000",
                        border: "1px solid #ffc107",
                        background: "#ffc107",
                      },
                    }}
                    fullWidth
                    onClick={() => {
                      navigate("/pricing");
                    }}
                  >
                    خرید
                  </Button>
                </Grid>
                <Grid item md={"auto"} xs={6}>
                  <Button
                    sx={{
                      color: "#ffc107",
                      border: "1px solid #ffc107",
                      borderRadius: 2,
                      padding: "5px 30px",
                    }}
                    fullWidth
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    ورود
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8} xs={12} order={{ md: 2, xs: 1 }}>
              <WorldMapView />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="headerAfter"></div>
    </>
  );
};
