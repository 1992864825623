import { Android, Apple, DesktopWindows } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { MasterView } from "../MasterView";

export const AndroidVipinoView = () => {
  return (
    <MasterView marginY={70}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} xs={12}>
            <Typography variant="h6" gutterBottom>
              1. دانلود اپلیکیشن
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ fontWeight: 700 }}
            >
              از طریق صفحه "دانلود" آخرین نسخه اپلیکیشن را دانلود و نصب کنید.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              component="a"
              href="/download"
              style={{ fontWeight: 700 }}
            >
              دانلود اپلیکیشن
            </Typography>
            <br />
            <br />
            <hr />
            <br />
            <Typography variant="h6" gutterBottom>
              2. پایان
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ fontWeight: 700 }}
            > شما با دانلود و نصب کانکشن هوشمند وی‌پی‌نو می توانید به راحتی و بدون هیچ تنظیم خاصی به سرویس های VPN وی‌پی‌نو دسترسی داشته باشید.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ fontWeight: 700, color: "#c55" }}
              >
                <b>توجه:</b> برای نصب و استفاده از این اپلیکیشن نیازمند اندروید 6 به بالا هستید.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};
