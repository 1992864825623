import { ErrorOutline, TextFieldsOutlined } from "@mui/icons-material";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MasterView } from "../MasterView";

export const IndexView = ({
  isPending = false,
  errorMessage,
  usernameFieldValue,
  usernameFieldOnChange,
  passwordFieldValue,
  passwordFieldOnChange,
  onSubmit,
}) => {
  const navigate = useNavigate();
  return (
    <MasterView marginY={70}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} xs={12}>
            <div className="containerBlock">
              <Typography
                variant="h5"
                gutterBottom
                fontWeight={700}
                sx={{ margin: "20px 0" }}
                textAlign="center"
              >
                ورود به حساب کاربری
              </Typography>
              {errorMessage && (
                <Alert
                  severity="error"
                  icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
                  sx={{ marginBottom: "12px" }}
                >
                  {errorMessage}
                </Alert>
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      id="standard-basic"
                      label="نام کاربری"
                      variant="standard"
                      value={usernameFieldValue}
                      onChange={usernameFieldOnChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="standard-password-input"
                      label="پسورد"
                      type="password"
                      autoComplete="current-password"
                      variant="standard"
                      value={passwordFieldValue}
                      onChange={passwordFieldOnChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      onClick={() => navigate("/forget")}
                      disabled={isPending ? true : false}
                      fullWidth
                    >
                      فراموشی پسورد
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSubmit}
                      disabled={isPending ? true : false}
                      type="submit"
                      fullWidth
                    >
                      ورود
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};
