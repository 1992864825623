import { ErrorOutline } from "@mui/icons-material";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { MasterView } from "../MasterView";
import { EmptyListView } from "../error/EmptyListView";

export const IndexView = ({
  list,
  expiredAt,
  username,
  password,
  emailAddress,
}) => {
  const buildOrders = () => {
    return (
      <Container sx={{ marginTop: 10 }}>
        <Typography
          variant="h5"
          gutterBottom
          fontWeight={700}
          sx={{ margin: "20px 0" }}
          textAlign="center"
        >
          سفارشات
        </Typography>
        <hr />
        <br />
        <Grid container direction="row" spacing={2}>
          {(!list && <></>) ||
            (list.length === 0 && (
              <EmptyListView>تا کنون خرید نداشته اید!</EmptyListView>
            )) ||
            list.map((item, index) => (
              <Grid key={index} item lg={3} sm={6} xs={12}>
                <div className="containerBlock">
                  <Typography
                    variant="h5"
                    gutterBottom
                    fontWeight={700}
                    sx={{ margin: "0 0 20px" }}
                    textAlign="center"
                  >
                    {item.product_name}
                  </Typography>
                  <hr />
                  <Grid container direction="row" sx={{ marginTop: 2 }}>
                    <Grid item xs>
                      <Typography
                        variant="body2"
                        gutterBottom
                        textAlign="start"
                      >
                        مدت
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        gutterBottom
                        fontWeight={700}
                        textAlign="end"
                      >
                        {item.duration} {item.timestamp}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs>
                      <Typography
                        variant="body2"
                        gutterBottom
                        textAlign="start"
                      >
                        کد پیگیری
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        gutterBottom
                        fontWeight={700}
                        textAlign="end"
                      >
                        {item.refid}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs>
                      <Typography
                        variant="body2"
                        gutterBottom
                        textAlign="start"
                      >
                        وضعیت
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        gutterBottom
                        fontWeight={700}
                        textAlign="end"
                      >
                        {item.state}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs>
                      <Typography
                        variant="body2"
                        gutterBottom
                        textAlign="start"
                      >
                        اپراتور
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        gutterBottom
                        fontWeight={700}
                        textAlign="end"
                      >
                        {item.operator}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs>
                      <Typography
                        variant="body2"
                        gutterBottom
                        textAlign="start"
                      >
                        تاریخ شروع
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        gutterBottom
                        fontWeight={700}
                        textAlign="end"
                      >
                        {item.created_at}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs>
                      <Typography
                        variant="body2"
                        gutterBottom
                        textAlign="start"
                      >
                        تاریخ انقضا
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        gutterBottom
                        fontWeight={700}
                        textAlign="end"
                      >
                        {item.expired_at}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            ))}
        </Grid>
      </Container>
    );
  };

  return (
    <MasterView marginY={70}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} xs={12}>
            <div className="containerBlock">
              <Typography
                variant="h5"
                gutterBottom
                fontWeight={700}
                sx={{ margin: "20px 0" }}
                textAlign="center"
              >
                حساب کاربری
              </Typography>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <TextField
                    id="standard-basic"
                    label="نام کاربری"
                    variant="standard"
                    value={username}
                    inputProps={{ readOnly: true, disableUnderline: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="standard-basic"
                    label="پسورد"
                    variant="standard"
                    value={password}
                    inputProps={{ readOnly: true, disableUnderline: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="standard-basic"
                    label="ایمیل"
                    variant="standard"
                    value={emailAddress}
                    inputProps={{ readOnly: true, disableUnderline: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="standard-basic"
                    label="تاریخ اتمام اشتراک"
                    variant="standard"
                    value={expiredAt}
                    inputProps={{ readOnly: true, disableUnderline: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
      {buildOrders()}
    </MasterView>
  );
};
