import React, { useEffect, useState } from "react";
import { IndexView } from "../views/order/IndexView";
import { useParams } from "react-router-dom";
import { RequestProvider } from "../providers/RequestProvider";
import {
  CookieValues,
  LinkValues,
  ResponseValues,
} from "../values/ConstantValues";

export const OrderController = () => {
  let { refid } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const request = new RequestProvider();

  useEffect(() => {
    if (orderData === null) {
      request.get(
        LinkValues.backend.shop.order.details(),
        {
          refid,
        },
        (response) => {
          if (response.status == 200 && response.data.hasOwnProperty("code")) {
            if (response.data.code == ResponseValues.code.ok) {
              setOrderData(response.data.data);
              return;
            }
            return setErrorMessage(response.data.message);
          }
        },
        (response) => {
          setErrorMessage(response.response.data.message);
        }
      );
    }
  }, [orderData]);
  return <IndexView data={orderData} errorMessage={errorMessage} />;
};
