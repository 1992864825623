import { CheckCircle, ErrorOutline } from "@mui/icons-material";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BeforeConnectionProblemDialogRaw } from "../common/DialogView";
import { MasterView } from "../MasterView";

export const IndexView = ({
  isPending = false,
  isSucceed,
  errorMessage,
  msgFieldValue,
  msgFieldOnChange,
  attachFieldOnChange,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const handleClose = (newValue) => {
    setOpen(false);
  };

  return (
    <MasterView marginY={70}>
      <Container>
        <BeforeConnectionProblemDialogRaw
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
        />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} xs={12}>
            <div className="containerBlock">
              <Typography
                variant="h5"
                gutterBottom
                fontWeight={700}
                sx={{ margin: "20px 0" }}
                textAlign="center"
              >
                گزارش مشکلات اتصال
              </Typography>
              <Alert
                severity="info"
                icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
                sx={{ margin: "20px 0" }}
              >
                در این قسمت میتوانید درخواست پشتیبانی جدید باز کنید و صبر کنید
                تا پشتیبان سایت به درخواست شما پاسخ دهد.
              </Alert>
              {(isSucceed && (
                <Alert
                  severity="success"
                  icon={<CheckCircle sx={{ margin: "0 0 0 12px" }} />}
                  sx={{ marginBottom: "12px" }}
                >
                  گزارش شما ثبت شد! به زودی از طریق ایمیل با شما در ارتباط
                  خواهیم بود.
                </Alert>
              )) || (
                <Alert
                  severity="info"
                  icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
                  sx={{ marginBottom: "12px" }}
                >
                  کاربر گرامی قبل از ارسال درخواست پشتیبانی به نکات زیر توجه
                  فرمایید:
                  <ul>
                    <li>حتما نام کاربری ، پسورد را ارسال کنید.</li>
                    <li>حتما مشکل خود را بدون حاشیه و به طور کامل شرح دهید.</li>
                    {/* <li>از تنظیمات و ارور اسکرین شات بفرستید.</li> */}
                    <li>
                      از تمام مراحلی که انجام می دهید اسکرین رکورد (فیلمبرداری
                      از صفحه) ارسال کنید.
                    </li>
                    <li>
                      حتما بگویید روی چه دستگاهی استفاده کردید ( ویندوز، مک ،
                      اندروید ، ios و… )
                    </li>
                  </ul>
                  در غیراین صورت تیکت شما بی جواب می ماند!
                </Alert>
              )}
              {errorMessage && (
                <Alert
                  severity="error"
                  icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
                  sx={{ marginBottom: "12px" }}
                >
                  {errorMessage}
                </Alert>
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <TextField
                      id="standard-basic"
                      label="متن تیکت"
                      variant="standard"
                      value={msgFieldValue}
                      onChange={msgFieldOnChange}
                      multiline
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Typography>پیوست</Typography>
                    <input
                      type="file"
                      name="file"
                      accept="image/*"
                      onChange={attachFieldOnChange}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSubmit}
                      disabled={isPending ? true : false}
                      type="submit"
                      fullWidth
                    >
                      ثبت تیکت
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};
