export const BackendValues = {
  appToken:
    "7lrzO7jbbeWfBvocKQw3lf3uqfOW0IVBt5uJVLRjrXnqHWVdnlXtr1648Xh5oHbyRQLSA4JLDDydL0PuVbsd7O2HYoXCnMWz9lzgd2CoM0ixP6WK7pBYKmp7Yo6xEaHu",
};

export const LinkValues = {
  backend: {
    // url: "http://localhost:8000/",
    url: "https://infinity-tech.ir/",
    account: {
      _self: (skipBaseUrl = false) => `${LinkValues.backend.url}account/`,
      user: {
        _self: (skipBaseUrl = false) =>
          `${LinkValues.backend.account._self(skipBaseUrl)}user/`,
        create: (skipBaseUrl = false) =>
          `${LinkValues.backend.account.user._self(skipBaseUrl)}create/`,
        login: (skipBaseUrl = false) =>
          `${LinkValues.backend.account.user._self(skipBaseUrl)}login/`,
        logout: (skipBaseUrl = false) =>
          `${LinkValues.backend.account.user._self(skipBaseUrl)}logout/`,
        forgot: (skipBaseUrl = false) =>
          `${LinkValues.backend.account.user._self(skipBaseUrl)}forgot/`,
        recovery: (skipBaseUrl = false) =>
          `${LinkValues.backend.account.user._self(skipBaseUrl)}recovery/`,
        track: (skipBaseUrl = false) =>
          `${LinkValues.backend.account.user._self(skipBaseUrl)}track/`,
        info: (skipBaseUrl = false) =>
          `${LinkValues.backend.account.user._self(skipBaseUrl)}info/`,
        elapsed: (skipBaseUrl = false) =>
          `${LinkValues.backend.account.user._self(skipBaseUrl)}elapsed/`,
      },
    },
    provider: {
      _self: (skipBaseUrl = false) => `${LinkValues.backend.url}provider/`,
      accesstoken: {
        _self: (skipBaseUrl = false) =>
          `${LinkValues.backend.provider._self(skipBaseUrl)}accesstoken/`,
        authenticate: (skipBaseUrl = false) =>
          `${LinkValues.backend.provider.accesstoken._self(
            skipBaseUrl
          )}authenticate/`,
      },
    },
    servers: {
      _self: (skipBaseUrl = false) => `${LinkValues.backend.url}server/`,
      list: {
        _self: (skipBaseUrl = false) =>
          `${LinkValues.backend.servers._self(skipBaseUrl)}list/`,
      },
      operator: {
        _self: (skipBaseUrl = false) =>
          `${LinkValues.backend.servers._self(skipBaseUrl)}operator/`,
        list: {
          _self: (skipBaseUrl = false) =>
            `${LinkValues.backend.servers.operator._self(skipBaseUrl)}list/`,
          user: (skipBaseUrl = false) =>
            `${LinkValues.backend.servers.operator.list._self(
              skipBaseUrl
            )}user/`,
        },
      },
      connectionProblem: {
        _self: (skipBaseUrl = false) =>
          `${LinkValues.backend.servers._self(skipBaseUrl)}connection_problem/`,
      },
      protocol: {
        _self: (skipBaseUrl = false) =>
          `${LinkValues.backend.servers._self(skipBaseUrl)}protocol/`,
        list: (skipBaseUrl = false) =>
          `${LinkValues.backend.servers.protocol._self(skipBaseUrl)}list/`,
      },
    },
    shop: {
      _self: (skipBaseUrl = false) => `${LinkValues.backend.url}shop/`,
      product: {
        _self: (skipBaseUrl = false) =>
          `${LinkValues.backend.shop._self(skipBaseUrl)}product/`,
        list: (skipBaseUrl = false) =>
          `${LinkValues.backend.shop.product._self(skipBaseUrl)}list/`,
      },
      order: {
        _self: (skipBaseUrl = false) =>
          `${LinkValues.backend.shop._self(skipBaseUrl)}order/`,
        details: (skipBaseUrl = false) =>
          `${LinkValues.backend.shop.order._self(skipBaseUrl)}details/`,
        buy: (skipBaseUrl = false) =>
          `${LinkValues.backend.shop.order._self(skipBaseUrl)}buy/`,
      },
    },
  },
};

export const ResponseValues = {
  code: {
    ok: "OK",
  },
};

export const CookieValues = {
  userCredential: "VUACRED",
};

export const Tools = {
  percentage: (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  },
  percentageOf: (percentageValue, totalValue) => {
    return (percentageValue / 100) * totalValue;
  },
};
