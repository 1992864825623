import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { RequestProvider } from "../providers/RequestProvider";
import {
  CookieValues,
  LinkValues,
  ResponseValues,
} from "../values/ConstantValues";
import NavigationView from "./common/NavigationView";

export const MasterView = ({ children, marginY }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    CookieValues.userCredential,
  ]);
  const request = new RequestProvider();

  useEffect(() => {
    if (cookies.hasOwnProperty(CookieValues.userCredential)) {
      request.get(
        LinkValues.backend.provider.accesstoken.authenticate(),
        {
          token: cookies[CookieValues.userCredential],
        },
        (response) => {
          if (
            response.status != 200 ||
            !response.data.hasOwnProperty("code") ||
            response.data.code != ResponseValues.code.ok
          ) {
            removeCookie(CookieValues.userCredential);
          }
        }
      );
    }
  }, []);

  return (
    <div>
      <NavigationView />
      <div style={{ marginTop: marginY, marginBottom: marginY }}>
        {children}
      </div>
    </div>
  );
};
