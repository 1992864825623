import {
  Alert,
  Button,
  Container,
  Grid,
  ListItemSecondaryAction,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { EmptyListView } from "../error/EmptyListView";
import { MasterView } from "../MasterView";
import QRCode from "react-qr-code";
import Chart from "chart.js/auto";
import { Bar, Doughnut } from "react-chartjs-2";
import { defaults } from "chart.js";
import { CheckCircle, ErrorOutline, ArrowDropDown } from "@mui/icons-material";
import {
  ConfirmationDialogRaw,
  OperatorChangeDialogRaw,
} from "../common/DialogView";
import { useNavigate } from "react-router-dom";

export const PanelView = ({
  protocolList,
  elapsedTime,
  selectedProtocol,
  selectedOperator,
  configList,
  handlerClickBuy,
  handlerOnProtocolSelect,
  isPendingConfig,
  isPendigOperator,
  operatorList,
  onChangeLocation,
  onChangeOperator,
}) => {
  return (
    <MasterView marginY={10}>
      <Container>
        {(!protocolList && <></>) ||
          (protocolList.length === 0 && (
            <NoSubscriptionView handlerClickBuy={handlerClickBuy} />
          )) || (
            <PanelDashboardView
              protocolList={protocolList}
              elapsedTime={elapsedTime}
              selectedProtocol={selectedProtocol}
              configList={configList}
              handlerOnProtocolSelect={handlerOnProtocolSelect}
              isPendingConfig={isPendingConfig}
              operatorList={operatorList}
              isPendigOperator={isPendigOperator}
              onChangeLocation={onChangeLocation}
              onChangeOperator={onChangeOperator}
              selectedOperator={selectedOperator}
            />
          )}
      </Container>
    </MasterView>
  );
};

const NoSubscriptionView = ({ handlerClickBuy }) => {
  return (
    <Grid container direction="row" spacing={2}>
      <EmptyListView>
        اشتراک شما به اتمام رسیده است! <br />
        <Button
          variant="contained"
          color="primary"
          onClick={handlerClickBuy}
          sx={{ marginTop: 2 }}
          fullWidth
        >
          خرید اشتراک
        </Button>
      </EmptyListView>
    </Grid>
  );
};

const PanelDashboardView = ({
  protocolList,
  elapsedTime,
  selectedProtocol,
  configList,
  handlerOnProtocolSelect,
  isPendingConfig,
  isPendigOperator,
  selectedOperator,
  operatorList,
  onChangeLocation,
  onChangeOperator,
}) => {
  defaults.font.family = "iranyekanfa";
  const navigate = useNavigate();

  return (
    <Grid container direction="column" spacing={2}>
      {/* <Grid item direction="row">
        <Alert
          severity="error"
          icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
          sx={{ marginBottom: "12px" }}
        >
          حمله سنگینی به پروتکل ها و سرور ها شده و تقریبا سرور ها از کار افتاده
          اند، ما همواره در حال تلاش هستیم که بهترینها را به شما عزیزان عرضه
          کنیم. اما رفع اختلال و حمله اخیر ممکن است چند روز زمان بر باشد و قادر
          به اتصال نباشید. به همین منظور مدت زمان اعتبار اکانت شما حفظ خواهد شد.
          امیدواریم عذر خواهی تمام اعضای گروه وی پی نو را بپذیرید.
        </Alert>
      </Grid>
      <Grid item direction="row">
        <Alert
          severity="success"
          icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
          sx={{ marginBottom: "12px" }}
        >
          به مناسبت کریسمس <b>3 روز</b> به مدت اعتبار اکانت شما اضافه شد!
        </Alert>
      </Grid> */}
      <Grid
        container
        item
        direction="row"
        md={0}
        xs={"auto"}
        display={{ md: "none", xs: "flex" }}
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              navigate("/download");
            }}
          >
            دانلود اپلیکیشن
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              navigate("/learn");
            }}
          >
            آموزش
          </Button>
        </Grid>
      </Grid>
      {/* <Grid item>
        <Alert
          severity="warning"
          icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
          sx={{ marginBottom: "12px" }}
        >
          پنل کاربری در حال بروزرسانی می باشد و ممکن است برخی از قابلیت ها قابل
          استفاده نباشند. پیشاپیش از صبر شما سپاسگذاریم!
        </Alert>
      </Grid> */}

      {/* <Grid item>
        <div className="containerBlock serverlist">
          <Bar
            data={{
              labels: [
                "فروردین",
                "اردیبهشت",
                "خرداد",
                "تیر",
                "مرداد",
                "شهریور",
                "مهر",
                "آبان",
                "آذر",
                "دی",
                "بهمن",
                "اسفند",
              ],
              datasets: [
                {
                  label: "ترافیک مصرفی",
                  backgroundColor: "rgba(54, 162, 235, 0.2)",
                  borderColor: "rgba(54, 162, 235, 1)",
                  borderWidth: 1,
                  // "rgba(255, 99, 132, 1)",
                  // "rgba(54, 162, 235, 1)",
                  // "rgba(255, 206, 86, 1)",
                  // "rgba(75, 192, 192, 1)",
                  // "rgba(153, 102, 255, 1)",
                  // "rgba(255, 159, 64, 1)",
                  data: [16, 12, 1, 5, 9, 1, 16, 12, 1, 5, 9, 1],
                },
              ],
            }}
            height={100}
          />
        </div>
      </Grid> */}

      <Grid
        item
        container
        direction="row"
        spacing={{ sm: 2, xs: 0 }}
        rowSpacing={2}
      >
        <Grid item lg={4} sm={4} xs={12} order={{ sm: 2, xs: 4 }}>
          <PanelDashboardElapsedDataView elapsedTime={elapsedTime} />
        </Grid>

        <Grid
          item
          lg={6}
          sm={6}
          xs={12}
          order={{ sm: 1, xs: 1 }}
          sx={{ display: "none" }}
        >
          <PanelDashboardProtocolDataView
            list={protocolList}
            selected={selectedProtocol}
            onChange={handlerOnProtocolSelect}
          />
        </Grid>

        <Grid item lg={4} sm={4} xs={12} order={{ sm: 3, xs: 2 }}>
          <PanelDashboardOperatorDataView
            list={operatorList}
            selected={selectedOperator}
            onChange={onChangeOperator}
          />
        </Grid>

        <Grid item lg={4} sm={4} xs={12} order={{ sm: 4, xs: 3 }}>
          <div className="containerBlock serverlist" style={{ padding: 0 }}>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight={700}
              sx={{ padding: "10px 20px 0" }}
            >
              کانفیگ
            </Typography>
            <hr />
            <div
              className="mysx"
              style={{ overflowY: "auto", padding: "21px" }}
            >
              {isPendingConfig ? (
                <Typography
                  variant="b"
                  gutterBottom
                  fontWeight={700}
                  sx={{ display: "block", margin: "30px 0" }}
                  textAlign="center"
                >
                  در حال بارگذاری ...
                </Typography>
              ) : (
                ((!configList || configList.length == 0) && (
                  <Typography
                    variant="b"
                    gutterBottom
                    fontWeight={700}
                    sx={{ display: "block", margin: "30px 0" }}
                    textAlign="center"
                  >
                    خطا هنگام بارگذاری کانفیگ
                  </Typography>
                )) || (
                  <PanelDashboardConfigDataView
                    code={selectedProtocol}
                    item={configList}
                    onChangeLocation={onChangeLocation}
                    onChangeOperator={onChangeOperator}
                    selectedOperator={selectedOperator}
                    operatorList={operatorList}
                  />
                )
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PanelDashboardElapsedDataView = ({ elapsedTime }) => {
  return (
    <div className="containerBlock serverlist" style={{ padding: 0 }}>
      <div className="mysx" style={{ overflowY: "auto", padding: "21px" }}>
        <Doughnut
          data={{
            labels: ["روز سپری شده", "روز باقی مانده"],
            datasets: [
              {
                label: "# of Votes",
                data: elapsedTime,
                backgroundColor: [
                  "rgba(230, 230, 230, 1)",
                  "rgba(54, 162, 235, 1)",
                ],
                borderWidth: 1,
              },
            ],
          }}
          height={100}
        />
      </div>
    </div>
  );
};

const PanelDashboardProtocolDataView = ({ list, selected, onChange }) => {
  return (
    <div className="containerBlock serverlist" style={{ padding: 0 }}>
      <Grid container direction="row" spacing={{ md: 0, xs: 2 }}>
        <Grid item md={12} xs>
          <Typography
            variant="h6"
            gutterBottom
            fontWeight={700}
            sx={{ padding: "10px 20px 0" }}
          >
            پروتکل
          </Typography>
        </Grid>
      </Grid>
      <hr />
      <div className="mysx" style={{ overflowY: "auto", padding: "21px" }}>
        {list.map((item, index) => (
          <Button
            key={index}
            variant="contained"
            color={selected === item.code ? "primary" : "secondary"}
            onClick={() => onChange(item.code)}
            sx={{ marginBottom: 2 }}
            fullWidth
          >
            {item.suggested && "🔴 "}
            {item.name}
            {item.suggested && " (پیشنهادی)"}
          </Button>
        ))}
      </div>
    </div>
  );
};

const PanelDashboardOperatorDataView = ({ list, selected, onChange }) => {
  return (
    <div className="containerBlock serverlist" style={{ padding: 0 }}>
      <Grid container direction="row" spacing={{ md: 0, xs: 2 }}>
        <Grid item md={12} xs>
          <Typography
            variant="h6"
            gutterBottom
            fontWeight={700}
            sx={{ padding: "10px 20px 0" }}
          >
            اپراتور
          </Typography>
        </Grid>
      </Grid>
      <hr />
      <div className="mysx" style={{ overflowY: "auto", padding: "21px" }}>
        {!list ? (
          <Typography
            variant="b"
            gutterBottom
            fontWeight={700}
            sx={{ display: "block", margin: "30px 0" }}
            textAlign="center"
          >
            در حال بارگذاری ...
          </Typography>
        ) : (
          <Grid container direction="row" spacing={1}>
            {list.map((item, index) => (
              <Grid item sm={12} xs key={index}>
                <Button
                  variant="contained"
                  color={selected === item.code ? "primary" : "secondary"}
                  onClick={() => onChange(item.code)}
                  fullWidth
                >
                  {item.name}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
};

const PanelDashboardConfigDataView = ({
  code,
  item,
  operatorList,
  selectedOperator,
  onChangeLocation,
  onChangeOperator,
}) => {
  const [open, setOpen] = React.useState(false);
  const [whatHasCopied, setWhatHasCopied] = React.useState(null);

  React.useEffect(() => {
    if (whatHasCopied) {
      setOpen(true);
    }
  }, [whatHasCopied]);

  const handleClick = (whc) => {
    if (whc === "server") {
      setWhatHasCopied("آدرس سرور");
    } else if (whc === "username") {
      setWhatHasCopied("نام کاربری");
    } else if (whc === "password") {
      setWhatHasCopied("پسورد");
    } else if (whc === "psk") {
      setWhatHasCopied("کلید - Preshared Key (PSK)");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const BuildSnack = () => {
    return (
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          severity="success"
          icon={
            <CheckCircle
              sx={{ margin: "0 -18px 0 12px", width: "19px", color: "#fff" }}
            />
          }
          sx={{ marginBottom: "12px", background: "#2e7d32", color: "#fff" }}
        >
          {whatHasCopied || ""} کپی شد!
        </Alert>
      </Snackbar>
    );
  };

  if (code === "VMESS") {
    return (
      <PanelDashboardConfigVmessView
        item={item}
        onChangeLocation={onChangeLocation}
        onClick_fields={handleClick}
        snack={BuildSnack}
      />
    );
  } else if (code === "CISCO_OC") {
    return (
      <PanelDashboardConfigCOCView
        item={item}
        onChangeLocation={onChangeLocation}
        onClick_fields={handleClick}
        snack={BuildSnack}
      />
    );
  } else if (code === "CISCO_AC") {
    return (
      <PanelDashboardConfigCACView
        item={item}
        onChangeLocation={onChangeLocation}
        onClick_fields={handleClick}
        snack={BuildSnack}
      />
    );
  } else if (code === "PPTP") {
    return (
      <PanelDashboardConfigPPTPView
        item={item}
        onChangeLocation={onChangeLocation}
        onClick_fields={handleClick}
        snack={BuildSnack}
      />
    );
  } else if (code === "IPSEC") {
    return (
      <PanelDashboardConfigIPSecView
        item={item}
        onChangeLocation={onChangeLocation}
        onClick_fields={handleClick}
        snack={BuildSnack}
      />
    );
  } else {
    return (
      <Typography
        variant="b"
        gutterBottom
        fontWeight={700}
        sx={{ display: "block", margin: "30px 0" }}
        textAlign="center"
      >
        خطا هنگام بارگذاری کانفیگ
      </Typography>
    );
  }
};

const PanelDashboardConfigVmessView = ({
  item,
  snack,
  onChangeLocation,
  onClick_fields,
}) => {
  return (
    <>
      {item.has_updated && (
        <Alert
          severity="success"
          icon={<CheckCircle sx={{ margin: "0 0 0 12px" }} />}
          sx={{ marginBottom: "12px" }}
        >
          بروزرسانی شده
        </Alert>
      )}
      <PanelDashboardConfigServerLocationView
        flag={item.country_flag}
        country={item.country_name}
        countryCode={item.country_code}
        locations={item.location_list}
        styles={{ marginBottom: 2 }}
        onChangeLocation={onChangeLocation}
      />
      <Button
        variant="contained"
        onClick={() => {
          onClick_fields();
          navigator.clipboard.writeText(item.value);
        }}
        sx={{ marginBottom: 2 }}
        fullWidth
      >
        کپی کد اتصال
      </Button>
      <TextField
        label="کد اتصال"
        variant="standard"
        value={item.value}
        inputProps={{ readOnly: true, disableUnderline: true }}
        multiline={true}
        className="cursorppp"
        onClick={() => {
          onClick_fields();
          navigator.clipboard.writeText(item.value);
        }}
        fullWidth
      />
      <div
        style={{
          height: "auto",
          margin: "20px auto 0",
          padding: "0 0",
          textAlign: "center",
          width: "100%",
        }}
      >
        <QRCode
          size={128}
          style={{
            height: "auto",
            margin: "auto",
            maxWidth: "100%",
            width: "200px",
          }}
          value={item.value || ""}
          viewBox={`0 0 128 128`}
        />
      </div>
      {snack()}
    </>
  );
};

const PanelDashboardConfigCOCView = ({
  item,
  snack,
  onChangeLocation,
  onClick_fields,
}) => {
  return (
    <>
      <PanelDashboardConfigServerLocationView
        flag={item.country_flag}
        country={item.country_name}
        countryCode={item.country_code}
        locations={item.location_list}
        styles={{ marginBottom: 2 }}
        onChangeLocation={onChangeLocation}
      />
      <TextField
        label="آدرس سرور"
        variant="standard"
        value={item.connection_server}
        inputProps={{ readOnly: true, disableUnderline: true }}
        sx={{
          marginBottom: "20px",
        }}
        className="cursorppp"
        onClick={() => {
          onClick_fields("server");
          navigator.clipboard.writeText(item.connection_server);
        }}
        fullWidth
      />
      <TextField
        label="نام کاربری"
        variant="standard"
        value={item.username}
        inputProps={{ readOnly: true, disableUnderline: true }}
        sx={{
          marginBottom: "20px",
        }}
        onClick={() => {
          onClick_fields("username");
          navigator.clipboard.writeText(item.username);
        }}
        className="cursorppp"
        fullWidth
      />
      <TextField
        label="گذرواژه"
        variant="standard"
        value={item.password}
        onClick={() => {
          onClick_fields("password");
          navigator.clipboard.writeText(item.password);
        }}
        className="cursorppp"
        inputProps={{ readOnly: true, disableUnderline: true }}
        fullWidth
      />
      {snack()}
    </>
  );
};

const PanelDashboardConfigCACView = ({
  item,
  snack,
  onChangeLocation,
  onClick_fields,
}) => {
  return (
    <>
      <PanelDashboardConfigServerLocationView
        flag={item.country_flag}
        country={item.country_name}
        countryCode={item.country_code}
        locations={item.location_list}
        styles={{ marginBottom: 2 }}
        onChangeLocation={onChangeLocation}
      />
      <TextField
        label="آدرس سرور"
        variant="standard"
        value={item.connection_server}
        inputProps={{ readOnly: true, disableUnderline: true }}
        sx={{
          marginBottom: "20px",
        }}
        onClick={() => {
          onClick_fields("server");
          navigator.clipboard.writeText(item.connection_server);
        }}
        className="cursorppp"
        fullWidth
      />
      <TextField
        label="نام کاربری"
        variant="standard"
        value={item.username}
        inputProps={{ readOnly: true, disableUnderline: true }}
        sx={{
          marginBottom: "20px",
        }}
        onClick={() => {
          onClick_fields("username");
          navigator.clipboard.writeText(item.username);
        }}
        className="cursorppp"
        fullWidth
      />
      <TextField
        label="گذرواژه"
        variant="standard"
        value={item.password}
        inputProps={{ readOnly: true, disableUnderline: true }}
        onClick={() => {
          onClick_fields("password");
          navigator.clipboard.writeText(item.password);
        }}
        className="cursorppp"
        fullWidth
      />
      {snack()}
    </>
  );
};

const PanelDashboardConfigIPSecView = ({
  item,
  snack,
  onChangeLocation,
  onClick_fields,
}) => {
  return (
    <>
      <PanelDashboardConfigServerLocationView
        flag={item.country_flag}
        country={item.country_name}
        countryCode={item.country_code}
        locations={item.location_list}
        styles={{ marginBottom: 2 }}
        onChangeLocation={onChangeLocation}
      />
      <TextField
        label="آدرس سرور"
        variant="standard"
        value={item.connection_server}
        inputProps={{ readOnly: true, disableUnderline: true }}
        sx={{
          marginBottom: "20px",
        }}
        onClick={() => {
          onClick_fields("server");
          navigator.clipboard.writeText(item.connection_server);
        }}
        className="cursorppp"
        fullWidth
      />
      <TextField
        label="نام کاربری"
        variant="standard"
        value={item.username}
        inputProps={{ readOnly: true, disableUnderline: true }}
        sx={{
          marginBottom: "20px",
        }}
        onClick={() => {
          onClick_fields("username");
          navigator.clipboard.writeText(item.username);
        }}
        className="cursorppp"
        fullWidth
      />
      <TextField
        label="گذرواژه"
        variant="standard"
        value={item.password}
        inputProps={{ readOnly: true, disableUnderline: true }}
        onClick={() => {
          onClick_fields("password");
          navigator.clipboard.writeText(item.password);
        }}
        className="cursorppp"
        fullWidth
      />
      {snack()}
    </>
  );
};

const PanelDashboardConfigPPTPView = ({
  item,
  snack,
  onChangeLocation,
  onClick_fields,
}) => {
  return (
    <>
      <PanelDashboardConfigServerLocationView
        flag={item.country_flag}
        country={item.country_name}
        countryCode={item.country_code}
        locations={item.location_list}
        styles={{ marginBottom: 2 }}
        onChangeLocation={onChangeLocation}
      />
      <TextField
        label="آدرس سرور"
        variant="standard"
        value={item.connection_server}
        inputProps={{ readOnly: true, disableUnderline: true }}
        sx={{
          marginBottom: "20px",
        }}
        onClick={() => {
          onClick_fields("server");
          navigator.clipboard.writeText(item.connection_server);
        }}
        className="cursorppp"
        fullWidth
      />
      <TextField
        label="Preshared Key (PSK)"
        variant="standard"
        value={item.value}
        inputProps={{ readOnly: true, disableUnderline: true }}
        sx={{
          marginBottom: "20px",
        }}
        onClick={() => {
          onClick_fields("psk");
          navigator.clipboard.writeText(item.value);
        }}
        className="cursorppp"
        fullWidth
      />
      <TextField
        label="نام کاربری"
        variant="standard"
        value={item.username}
        inputProps={{ readOnly: true, disableUnderline: true }}
        sx={{
          marginBottom: "20px",
        }}
        onClick={() => {
          onClick_fields("username");
          navigator.clipboard.writeText(item.username);
        }}
        className="cursorppp"
        fullWidth
      />
      <TextField
        label="گذرواژه"
        variant="standard"
        value={item.password}
        inputProps={{ readOnly: true, disableUnderline: true }}
        onClick={() => {
          onClick_fields("password");
          navigator.clipboard.writeText(item.password);
        }}
        className="cursorppp"
        fullWidth
      />
      {snack()}
    </>
  );
};

const PanelDashboardConfigServerLocationView = ({
  flag,
  country,
  countryCode,
  locations,
  styles,
  onChangeLocation,
}) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(countryCode);

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  React.useEffect(() => {
    if (countryCode !== value) {
      onChangeLocation(value);
    }
  }, [value]);

  return (
    <>
      <Typography gutterBottom variant="caption" sx={{ display: "block" }}>
        انتخاب سرور
      </Typography>
      {/* <Typography
        gutterBottom
        variant="caption"
        sx={{ display: "block", fontWeight: 700, fontSize: 15, color: "#c55" }}
      >
        باتوجه به اپراتور اینترنت خود سرور را انتخاب نمایید:
      </Typography> */}
      <Button
        sx={{
          ...styles,
          padding: "10px 16px 0px",
        }}
        variant="contained"
        fullWidth
        onClick={handleClickListItem}
      >
        <Grid container direction="row" spacing={2}>
          <Grid item xs>
            <div style={{ display: "flex", gap: 10 }}>
              <img src={flag} height="23px" />
              <Typography variant="body1">{country}</Typography>
            </div>
          </Grid>
          <Grid item xs={"auto"}>
            <ArrowDropDown />
          </Grid>
        </Grid>
      </Button>
      <ConfirmationDialogRaw
        id="ringtone-menu"
        keepMounted
        open={open}
        onClose={handleClose}
        value={value}
        datalist={locations}
      />
    </>
  );
};
