import { ErrorOutline, TextFieldsOutlined } from "@mui/icons-material";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MasterView } from "../MasterView";

export const IndexView = ({
  isPending = false,
  errorMessage,
  accountDetails,
  emailFieldValue,
  emailFieldOnChange,
  cardFieldValue,
  cardFieldOnChange,
  msgFieldValue,
  msgFieldOnChange,
  repFieldOnChange,
  getBack,
  onSubmit,
}) => {
  const navigate = useNavigate();
  return (
    <MasterView marginY={70}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} xs={12}>
            <div className="containerBlock">
              <Typography
                variant="h5"
                gutterBottom
                fontWeight={700}
                sx={{ margin: "20px 0" }}
                textAlign="center"
              >
                پیگیری اکانت
              </Typography>
              {errorMessage && (
                <Alert
                  severity="error"
                  icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
                  sx={{ marginBottom: "12px" }}
                >
                  {errorMessage}
                </Alert>
              )}
              {(accountDetails && (
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      label="نام کاربری"
                      variant="standard"
                      value={accountDetails.username}
                      inputProps={{ readOnly: true, disableUnderline: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="پسورد"
                      variant="standard"
                      value={accountDetails.password}
                      inputProps={{ readOnly: true, disableUnderline: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={getBack}
                      fullWidth
                    >
                      بازگشت
                    </Button>
                  </Grid>
                </Grid>
              )) || (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                  }}
                >
                  <Grid container direction="column" spacing={3}>
                    <Grid item>
                      <TextField
                        label="ایمیلی که در زمان خرید ثبت کردید*"
                        variant="standard"
                        value={emailFieldValue}
                        onChange={emailFieldOnChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="شماره کارتی که با آن پرداخت انجام داده اید*"
                        variant="standard"
                        value={cardFieldValue}
                        onChange={cardFieldOnChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="توضیحات"
                        variant="standard"
                        value={msgFieldValue}
                        onChange={msgFieldOnChange}
                        multiline
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <Typography>تصویر از تراکنش</Typography>
                      <Typography variant="caption">
                        جهت پشتیبانی دقیق تر اگر از تراکنش واریزی عکس تهیه کرده
                        اید ارسال نمایید.
                      </Typography>
                      <br />
                      <br />
                      <input
                        type="file"
                        name="file"
                        accept="image/*"
                        onChange={repFieldOnChange}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        disabled={isPending ? true : false}
                        type="submit"
                        fullWidth
                      >
                        ثبت
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};
