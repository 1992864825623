import { Android, Apple, DesktopWindows } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Player } from "video-react";
import { MasterView } from "../MasterView";

export const IosOneClickView = () => {
  const [videoLink, setVideoLink] = React.useState("");
  React.useEffect(() => {
    setVideoLink(window.location.origin + "/assets/learn/oneclick-ios.mp4");
  }, []);

  return (
    <MasterView marginY={70}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} xs={12}>
            <Typography variant="h6" gutterBottom>
              1. دانلود اپلیکیشن
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ fontWeight: 700 }}
            >
              از طریق صفحه "دانلود" آخرین نسخه اپلیکیشن را دانلود و نصب کنید.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              component="a"
              href="/download"
              style={{ fontWeight: 700 }}
            >
              دانلود اپلیکیشن
            </Typography>
            <br />
            <br />
            <hr />
            <br />
            <Typography variant="h6" gutterBottom>
              2. تنظیم کانفیگ و اتصال
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ fontWeight: 700 }}
            >
              مطابق ویدئوی زیر پروتکل Ray را تنظیم کنید
            </Typography>
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <Player src={videoLink} />
            </div>
            <br />
            <hr />
            <br />
            <Typography variant="h6" gutterBottom>
              3. اتصال با موفقیت انجام شد
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};
