import React, { useState } from "react";
import { RequestProvider } from "../providers/RequestProvider";
import {
  CookieValues,
  LinkValues,
  ResponseValues,
} from "../values/ConstantValues";
import { IndexView } from "../views/login/IndexView";
import { useCookies } from "react-cookie";

export const LoginController = () => {
  const [usernameField, setUsernameField] = useState("");
  const [passwordField, setPasswordField] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [cookies, setCookie] = useCookies([CookieValues.userCredential]);
  const request = new RequestProvider();

  const handlerUsernameFieldOnChange = (e) => {
    setUsernameField(e.target.value);
  };
  const handlerPasswordFieldOnChange = (e) => {
    setPasswordField(e.target.value);
  };

  const handlerOnSubmit = () => {
    setErrorMessage(null);
    if (!usernameField || !passwordField) {
      setErrorMessage("لطفا تمام فیلد ها را به درستی پر کنید.");
      return;
    }
    setIsPending(true);
    request.get(
      LinkValues.backend.account.user.login(false),
      {
        username: usernameField,
        password: passwordField,
      },
      (response) => {
        if (response.status == 200 && response.data.hasOwnProperty("code")) {
          if (response.data.code == ResponseValues.code.ok) {
            setCookie(CookieValues.userCredential, response.data.data, {
              path: "/",
            });
            window.location.href = "/";
          } else {
            setErrorMessage(response.data.message);
          }
        }
      },
      null,
      () => {
        setIsPending(false);
      }
    );
  };

  return (
    <IndexView
      isPending={isPending}
      errorMessage={errorMessage}
      usernameFieldValue={usernameField}
      usernameFieldOnChange={handlerUsernameFieldOnChange}
      passwordFieldValue={passwordField}
      passwordFieldOnChange={handlerPasswordFieldOnChange}
      onSubmit={handlerOnSubmit}
    />
  );
};
