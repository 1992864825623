import { Android, Apple, DesktopWindows } from "@mui/icons-material";
import {
  Alert,
  Button,
  Container,
  Grid,
  ListItemSecondaryAction,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { EmptyListView } from "../error/EmptyListView";
import { MasterView } from "../MasterView";
import QRCode from "react-qr-code";
import Chart from "chart.js/auto";
import { Bar, Doughnut } from "react-chartjs-2";
import { defaults } from "chart.js";
import { CheckCircle, ErrorOutline, ArrowDropDown } from "@mui/icons-material";
import {
  ConfirmationDialogRaw,
  OperatorChangeDialogRaw,
} from "../common/DialogView";
import { useNavigate } from "react-router-dom";

export const PanelConfigView = ({onShowConfigClicked, onAndroidClicked}) => {
  return (
    <MasterView marginY={10}>
      <Container>
        <Grid container direction="row" spacing={2} justifyContent="center">
          <Grid item lg={6} sm={6} xs={12}>
            <div className="containerBlock serverlist" style={{ padding: 0 }}>
              <Grid container direction="row" spacing={{ md: 0, xs: 2 }}>
                <Grid item md={12} xs>
                  <Typography
                    variant="h6"
                    gutterBottom
                    fontWeight={700}
                    sx={{ padding: "10px 20px 0" }}
                  >
                    دستگاه خود را انتخاب کنید
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <div style={{ overflowY: "auto", padding: "21px" }}>
                  <Grid container direction="row" spacing={1}>
                      <Grid item sm={12} xs>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => onAndroidClicked()}
                          fullWidth
                        >
                        <Android
                          style={{
                            width: "18px",
                          }}
                        />
                        <span
                          style={{
                            padding: 2,
                          }}
                        ></span>
                          کانکشن اختصاصی اندروید وی‌پی‌نو
                        </Button>
                      </Grid>
                      <Grid item sm={12} xs>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => onShowConfigClicked()}
                          fullWidth
                        >
                        {/* <Android
                          style={{
                            width: "18px",
                          }}
                        />
                        <span
                          style={{
                            padding: 2,
                          }}
                        ></span>
                          اندروید

                        <span
                          style={{
                            padding: 2,
                          }}
                        ></span>
                        | */}
                        <span
                          style={{
                            padding: 2,
                          }}
                        ></span>

                        <Apple
                          style={{
                            width: "18px",
                          }}
                        />
                        <span
                          style={{
                            padding: 2,
                          }}
                        ></span>آیفون
                        
                        <span
                          style={{
                            padding: 2,
                          }}
                        ></span>
                        |
                        <span
                          style={{
                            padding: 2,
                          }}
                        ></span>

                        <DesktopWindows
                          style={{
                            width: "18px",
                          }}
                        />
                        <span
                          style={{
                            padding: 2,
                          }}
                        ></span>ویندوز
                        
                        </Button>
                      </Grid>
                  </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </MasterView>
  );
};