import { ArrowDownward, ErrorOutline } from "@mui/icons-material";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import { Container } from "@mui/system";
import React from "react";
import CurrencyFormat from "react-currency-format";
import { Tools } from "../../values/ConstantValues";
import { EmptyListView } from "../error/EmptyListView";
import { MasterView } from "../MasterView";
import { UpListView } from "../error/UpListView";

export const IndexView = ({
  isPending,
  errorMessage,
  planList,
  operatorList,
  selectedOperator,
  onChange_Operator,
  onSubmit_Plan,
}) => {
  return (
    <MasterView marginY={70}>
      <Container>
        {(isPending && <>چند لحظه صبر کنید...</>) || (
          <>
            {errorMessage && (
              <Alert
                severity="error"
                icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
                sx={{ marginBottom: "12px" }}
              >
                {errorMessage}
              </Alert>
            )}
            <Alert
              severity="info"
              icon={<ErrorOutline sx={{ margin: "0 0 0 12px" }} />}
              sx={{ marginBottom: "12px" }}
            >
              جهت خرید اکانت برای تعداد بیش از یک کاربر، ایمیل های جداگانه وارد
              نمایید و از استفاده یک ایمیل برای اکانت های دیگر خودداری کنید.
            </Alert>
          </>
        )}
        <IndexOperatorView
          list={operatorList}
          selected={selectedOperator}
          onChange={onChange_Operator}
        />
        <IndexPlanView list={planList} onSubmit={onSubmit_Plan} />
      </Container>
    </MasterView>
  );
};

const IndexOperatorView = ({ list, selected, onChange }) => {
  return (
    <>
      <Typography
        variant="b"
        gutterBottom
        fontWeight={700}
        sx={{ display: "block", margin: "30px 0" }}
        textAlign="center"
      >
        انتخاب اپراتور
      </Typography>
      <Grid container direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        {(!list && <></>) ||
          (list.length === 0 && (
            <Typography
              variant="b"
              gutterBottom
              fontWeight={700}
              sx={{ display: "block", margin: "30px 0" }}
              textAlign="center"
            >
              در حال بارگذاری ...
            </Typography>
          )) ||
          list.map((item, index) => (
            <Grid key={index} item lg={3} sm={6} xs={12}>
              <Button
                key={index}
                variant="contained"
                color={selected === item.code ? "primary" : "secondary"}
                onClick={() => onChange(item.code)}
                fullWidth
              >
                {(item.suggested || item.offer) && "🔴 "}
                {item.name}
                {item.offer && " (تخفیف ویژه)"}
                {item.suggested && " (پیشنهادی)"}
              </Button>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

const IndexPlanView = ({ list, onSubmit }) => {
  return (
    <>
      <hr />
      <Typography
        variant="b"
        gutterBottom
        fontWeight={700}
        sx={{ display: "block", margin: "30px 0" }}
        textAlign="center"
      >
        انتخاب پلن
      </Typography>
      <Grid container direction="row" spacing={2}>
        {(!list && <></>) ||
          (list.length === 0 && (
            <UpListView>ابتدا اپراتور مورد نظر را انتخاب کنید</UpListView>
          )) ||
          list.map((item, index) => (
            <Grid key={index} item lg={3} sm={6} xs={12}>
              <div className="containerBlock" style={{ padding: "21px 14px" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  fontWeight={700}
                  sx={{ margin: "0 0 20px" }}
                  textAlign="center"
                >
                  {item.title}
                </Typography>
                <hr />

                {item.off_percentage > 0 && (
                  <Grid container direction="row" sx={{ marginTop: 2 }}>
                    <Grid item xs>
                      <Alert
                        severity="info"
                        icon={
                          <ProductionQuantityLimitsIcon
                            sx={{ margin: "0 0 0 12px" }}
                          />
                        }
                        sx={{ padding: 0 }}
                      >
                        <CurrencyFormat
                          value={Math.floor(item.off_percentage)}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={0}
                          prefix={" "}
                          suffix={"% تخفیف "}
                        />
                        به مدت محدود
                      </Alert>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  direction="row"
                  sx={{ marginTop: 0, padding: "5px 10px" }}
                >
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      textAlign="start"
                      sx={{
                        marginTop: "4px",
                      }}
                    >
                      مدت
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      gutterBottom
                      fontWeight={700}
                      textAlign="end"
                    >
                      {item.duration} {item.timestamp}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid container direction="row">
                <Grid item xs>
                  <Typography
                    variant="body2"
                    gutterBottom
                    textAlign="start"
                  >
                    موقعیت
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    gutterBottom
                    fontWeight={700}
                    textAlign="end"
                  >
                    {item.locations}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs>
                  <Typography
                    variant="body2"
                    gutterBottom
                    textAlign="start"
                  >
                    پروتکل
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    gutterBottom
                    fontWeight={700}
                    textAlign="end"
                  >
                    {item.protocols}
                  </Typography>
                </Grid>
              </Grid> */}
                <Grid
                  container
                  direction="row"
                  sx={{
                    background: "#eee",
                    borderRadius: "7px",
                    padding: "0 10px",
                  }}
                >
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      textAlign="start"
                      sx={{
                        marginTop: "4px",
                      }}
                    >
                      قیمت
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={"auto"}
                    direction={"column"}
                    alignItems={"center"}
                  >
                    {console.log(item.off_amount)}
                    {(item.off_amount > 0.0 && (
                      <>
                        <Typography
                          variant="caption"
                          fontWeight={400}
                          sx={{
                            textDecoration: "line-through",
                            marginTop: "0.35em",
                          }}
                          textAlign="end"
                        >
                          <CurrencyFormat
                            value={item.amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={" تومان "}
                          />
                        </Typography>
                        <ArrowDownward fontSize="15px" />
                        <Typography
                          variant="caption"
                          gutterBottom
                          fontWeight={700}
                          textAlign="end"
                        >
                          <CurrencyFormat
                            value={Math.floor(item.off_amount)}
                            decimalScale={0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={" تومان "}
                          />
                        </Typography>
                      </>
                    )) || (
                      <Typography
                        variant="caption"
                        gutterBottom
                        fontWeight={700}
                        sx={{
                          marginTop: "0.35em",
                        }}
                        textAlign="end"
                      >
                        <CurrencyFormat
                          value={item.amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={" تومان "}
                        />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="row" sx={{ padding: "5px 10px" }}>
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      textAlign="start"
                      sx={{
                        marginTop: "4px",
                      }}
                    >
                      تعداد کاربر
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      gutterBottom
                      fontWeight={700}
                      textAlign="end"
                    >
                      {item.user_count} کاربره
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  sx={{
                    background: "#eee",
                    borderRadius: "7px",
                    padding: "0 10px",
                  }}
                >
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      textAlign="start"
                      sx={{
                        marginTop: "4px",
                      }}
                    >
                      هدیه
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      gutterBottom
                      fontWeight={700}
                      textAlign="end"
                    >
                      {item.free_day} روز
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" sx={{ padding: "5px 10px" }}>
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      textAlign="start"
                      sx={{
                        marginTop: "4px",
                      }}
                    >
                      پلتفرم ها
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      gutterBottom
                      fontWeight={700}
                      textAlign="end"
                    >
                      همه پلتفرم ها
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  sx={{
                    marginBottom: 2,
                    background: "#eee",
                    borderRadius: "7px",
                    padding: "0 10px",
                  }}
                >
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      textAlign="start"
                      sx={{
                        marginTop: "4px",
                      }}
                    >
                      نوع سرور ها
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      gutterBottom
                      fontWeight={700}
                      textAlign="end"
                    >
                      اختصاصی
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  sx={{
                    marginBottom: (item.off_amount > 0 && 0) || 1,
                  }}
                >
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      textAlign="center"
                      sx={
                        item.off_amount > 0
                          ? {
                              background: "#ddd",
                              padding: "5px 10px",
                              borderRadius: "100px",
                              border: "2px dotted #999",
                              textDecoration: "line-through",
                              transform: "scale(0.9)",
                              opacity: 0.5,
                            }
                          : {
                              background: "#eee",
                              padding: "5px 10px",
                              borderRadius: "100px",
                              border: "2px dashed #c55",
                              marginBottom: "0.35em",
                            }
                      }
                    >
                      ماهانه:{" "}
                      <CurrencyFormat
                        value={
                          (item.duration > 1 &&
                            Math.floor(item.amount / item.duration)) ||
                          item.amount
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={" تومان "}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                {item.off_amount > 0 && (
                  <>
                    <Grid
                      container
                      direction="row"
                      sx={{ marginBottom: 1 }}
                      justifyContent={"center"}
                    >
                      <ArrowDownward fontSize="15px" />
                    </Grid>
                    <Grid container direction="row" sx={{ marginBottom: 1 }}>
                      <Grid item xs>
                        <Typography
                          variant="body2"
                          gutterBottom
                          textAlign="center"
                          sx={{
                            background: "#eee",
                            padding: "5px 10px",
                            borderRadius: "100px",
                            border: "2px dashed #c55",
                          }}
                        >
                          ماهانه:{" "}
                          <CurrencyFormat
                            value={
                              (item.duration > 1 &&
                                Math.floor(item.off_amount / item.duration)) ||
                              item.off_amount
                            }
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            suffix={" تومان "}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container direction="row" sx={{ marginBottom: 2 }}>
                  <Grid item xs>
                    <Typography variant="body2" gutterBottom textAlign="center">
                      پرداخت آنلاین و تحویل آنی
                    </Typography>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onSubmit(item.code)}
                  fullWidth
                >
                  خرید
                </Button>
              </div>
            </Grid>
          ))}
      </Grid>
    </>
  );
};
